import React, {cloneElement, forwardRef} from "react";
import {Tooltip} from "@mui/material";

const TooltipWrapper = forwardRef(({children, ...props}, ref) => (
    <div {...props} ref={ref}>{cloneElement(children, props)}</div>
));

export const MyTooltip = ({children, ...props}) => (
    <Tooltip {...props}>
        <TooltipWrapper>{children}</TooltipWrapper>
    </Tooltip>
);
