import validator from "validator";
import moment from "moment";


const __required = (message = 'resources.validation.required') =>
    value => value !== null && value !== undefined && value.toString().trim() ? undefined : message;

const __length = (size, message = 'resources.validation.length') =>
    value => value && value.toString().length !== size ? {message: message, args: {size}} : undefined;

const __number = (message = 'resources.validation.number') =>
    value => value && isNaN(Number(value)) ? message : undefined;

const __email = (message = 'resources.validation.invalid_email') =>
    value => value && !validator.isEmail(value) ? message : undefined;

const __positiveNumber = (message = 'resources.validation.positive_number') =>
    value => value && Number(value) < 0 ? message : undefined;

const __integer = (message = 'resources.validation.integer') =>
    value => value && !Number.isInteger(Number(value)) ? message : undefined;

const __withoutSpace = (message = 'resources.validation.without_space') =>
    value => value && /\s/.test(value) ? message : undefined;

export const required = [__required()];

export const positiveInteger = [__number(), __positiveNumber(), __integer()]

export const requiredLen8 = [__required(), __number(), __length(8)];

export const requiredEmail = [__required(), __email()];

export const positiveNumber = [__number(), __positiveNumber()]

export const requiredPositiveNumber = [__required(), __number(), __positiveNumber()]

export const requiredWithoutSpace = [__required(), __withoutSpace()]

export const validateContractForm = (values) => {
    const errors = {};
    validateValidityDateTimes(values, errors);
    validateDuties(values, errors);
    return errors;
};

const validateValidityDateTimes = ({validFrom, validUntil}, errors) => {
    if (validFrom && validUntil && (moment.utc(validFrom).isAfter(moment.utc(validUntil)))) {
        errors.validUntil = ['resources.validation.from_after_until'];
    }
};

const validateDuties = ({duties, validFrom}, errors) => {
    if (duties) {
        errors.duties = duties.map(d => ({
            address: {
                id: d && d.address && d.address.id ? undefined : ['resources.validation.required']
            },
            startDate: !d ? undefined : !d.startDate ? ['resources.validation.required'] : (!validFrom || (moment.utc(d.startDate).isSameOrAfter(moment.utc(validFrom)))) ? undefined : ['resources.validation.start_date_before_validity_from'],
            periodicity: d && d.periodicity ? undefined : ['resources.validation.required'],
        }));
    }
};