import React, {Fragment, useState} from 'react'
import OrderIcon from '@mui/icons-material/PlaylistAddCheck';
import {
    Button,
    useNotify,
    useDataProvider,
    Confirm,
    useTranslate,
    setListSelectedIds,
    SimpleForm,
    DateInput,
    useRefresh,
} from 'react-admin';
import {useDispatch} from "react-redux";
import moment from "moment";
import DialogContentText from '@material-ui/core/DialogContentText';
import {toUtcFix} from "./utils";


export const GenerateOrdersButton = ({selectedIds, generateAll, resource}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const defaultFrom = moment().startOf('year');
    const defaultUntil = moment().endOf('month');
    const [from, setFrom] = useState(defaultFrom);
    const [until, setUntil] = useState(defaultUntil);
    const isSomethingSelected = selectedIds && selectedIds.length > 0;

    const handleGenerate = e => {
        e.stopPropagation();
        if (!generateAll && (!selectedIds || selectedIds.length === 0)) {
            notify('app.message.nothing_selected', 'warning');
            return;
        }

        const ids = generateAll ? [] : selectedIds;
        setLoading(true);
        const params = resource === 'contracts' ? {contractIds: ids, from: from, until: until}
        : resource === 'duties' ? {dutyIds: ids, from: from, until: until}
        : {}
        dataProvider
            .generateOrders(params)
            .then(({data}) => {
                notify('app.message.generated_orders', 'info', {count: data});
                handleDialogClose();
                dispatch(setListSelectedIds(resource, []));
                refresh();
            })
            .catch(() => {
                notify('app.message.generated_orders_fail', 'warning')
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Button onClick={handleClick} label='app.message.generate'>
            <OrderIcon/>
        </Button>
        <Confirm
            isOpen={isOpen}
            loading={isLoading}
            title={isSomethingSelected ? 'app.message.generate_confirm_selected_title' : 'app.message.generate_confirm_all_title'}
            content={
                <Fragment>
                    <DialogContentText>
                        {translate('app.message.generate_confirm_content')}
                    </DialogContentText>
                    <SimpleForm toolbar={false}>
                        <DateInput
                            fullWidth
                            source="from"
                            label="app.message.generate_from"
                            onChange={e => setFrom(e?.target?.value)}
                            defaultValue={defaultFrom}
                        />
                        <DateInput
                            fullWidth
                            source="until"
                            label="app.message.generate_until"
                            onChange={e => setUntil(toUtcFix(e?.target?.value))}
                            defaultValue={defaultUntil}
                        />
                    </SimpleForm>
                </Fragment>
            }
            onConfirm={handleGenerate}
            onClose={handleDialogClose}
        />
    </Fragment>
};
