export default {
    app: {
        title: "Ferdo Informačný systém",
        email: "Emailová adresa",
        login: {
            title: "Vitajte v informačnom systéme firmy Ferdo",
            form_title: "Prihlásenie",
            forgotten_password: "Zabudnuté heslo?",
            forgotten_password_dlg_title: "Zabudnuté heslo",
            new_password: "Nové heslo",
            forgotten_password_dlg_msg: "Vyplňte, prosím, Vašu emailovú adresu a stlačte tlačidlo reset. Na Vašu adresu bude zaslaný link na obnovenie hesla.",
            password_reset_sent_msg: "Email s linkou na obnovenie hesla bol zaslaný na Vami zadanú adresu.",
            support: "Kontakt/Podpora",
            errors: {
                general: "Chyba prihlasovania",
                inactive: "Užívateľ neaktívny",
                user_not_found: "Užívateľ neexistuje",
                invalid_email: "Zadaný email je nesprávny",
                invalid_password: "Nesprávne heslo",
                network_error: "Autentifikačný server neodpovedá",
                invalid_token: "Nesprávny token, nutné nové prihlásenie",
            }
        },
        http_message: {
            error_code: {
                404: "Záznam nebol nájdený v databáze",
                405: "Táto operácia nie je povolená",
                1062: "Záznam už existuje v databáze",
                1451: "Záznam je niekde použitý a nemôže byť zmazaný",
            },
        },
        message: {
            open: "Otvor",
            close: "Zavrieť",
            reset: "Reset",
            send: "Poslať",
            save: "Uložiť",
            cancel: "Zrušiť",
            generate: "Generovať",
            created: "Vytvorené",
            created_by: "Vytvorené %{date} užívateľom %{user}",
            expand_show: "Zobraziť detaily",
            expand_hide: "Skryť detaily",
            order_code: "Formát kódu objednávky: %{code}",
            unknown_periodicity: "Vybraná periodicita nenájdená",
            unknown_contract_number: "<č. zmluvy>",
            unknown_subcontract_number: "<dodatok>",
            order_number: "<č. obj.>",
            nothing_selected: "Žiadna položka nie je vybratá",
            generated_orders: "Vygenerované objednávky v počte %{count}",
            generate_from: "Generovať od dátumu",
            generate_until: "Generovať do dátumu",
            generated_orders_fail: "Vyskytol sa problém pri generovaní, prosím kontaktujte správcu systému",
            generate_confirm_all_title: "Generovať všetky objednávky",
            generate_confirm_selected_title: "Generovať vybrané objednávky",
            generate_confirm_content: "Ste si istý, že chcete generovať objednávky?",
            assignee: "Zmeniť zamestnanca",
            assignee_title: "Vyberte nového zamestnanca",
            assigned_employee: "Úspešne zmenený zamestnanec",
            assigned_employee_fail: "Vyskytol sa problém pri zmene zamestnanca, prosím kontaktujte správcu systému",
            update_order_status: "Zmeniť stav",
            update_order_status_title: "Vyberte nový stav pre objednávky",
            updated_order_status: "Úspešne zmenený stav",
            updated_order_status_fail: "Vyskytol sa problém pri zmene stavu, prosím kontaktujte správcu systému",
            update_order_bonus: "Zmeniť príplatok",
            update_order_bonus_title: "Vyberte nový príplatok pre objednávky",
            updated_order_bonus: "Úspešne zmenený príplatok",
            updated_order_bonus_fail: "Vyskytol sa problém pri zmene príplatok, prosím kontaktujte správcu systému",
            updated_order_realization_date: "Úspešne zmenený realizačný dátum",
            updated_order_realization_date_fail: "Vyskytol sa problém pri zmene realizačného dátumu. Skontrolujte, či sa rovnaký typ objednávky už nenachádza v danom dni.",
            updated_order_realization_date_and_assign: "Úspešne zmenený realizačný dátum alebo zamestnanec",
            updated_order_realization_date_and_assign_fail: "Vyskytol sa problém pri zmene realizačného dátumu alebo zamestnanca. Skontrolujte, či sa rovnaký typ objednávky už nenachádza v danom dni.",
            update_realization_date: "Zmeniť realizačný dátum",
            update_realization_date_title: "Vyberte nový realizačný dátum",
            updated_realization_date: "Úspešne zmenený realizačný dátum",
            updated_realization_date_fail: "Vyskytol sa problém pri zmene realizačného dátumu. Skontrolujte, či sa rovnaký typ objednávky už nenachádza v danom dni.",
            create_partner: "Vytvor Partnera",
            create_activity: "Vytvor Úkon",
            update_partner: "Uprav Partnera",
            executed_order_confirm_title: "Objednávka vykonaná",
            executed_order_confirm_content: "Ste si istí, že chcete označiť vybranú objednávku ako splnenú?",
            executed_order_button: "Vykonaná",
            filter_orders_button: "Objednávky",
        },
        users: {
            title: "Manažment používateľov",
            admin: "Admin",
            app: "Aplikácia",
            user_count: "Počet používateľov",
            provider: "Poskytovateľ",
            create_user: "Vytvoriť nového používateľa",
            edit_user: "Upraviť používateľa",
            error_loading: "Chyba pri načítaní používateľov",
            user_created: "Používateľ úspešne vytvorený",
            user_create_error: "Chyba pri vytváraní používateľa",
            user_updated: "Používateľ úspešne aktualizovaný",
            user_update_error: "Chyba pri aktualizácií používateľa",
            user_delete_dialog_title: "Zmazať používateľa",
            user_delete_dialog_content: "Ste si istí, že chcete zmazať používateľa: ",
            user_deleted: "Používateľ odstránený",
            user_delete_error: "Chyba pri odstraňovaní používateľa",
            roles: {
                user: "Jednoduchý používateľ",
                admin: "Administrátor",
                super_admin: "Správca systému",
            },
        },
        duty_periodicity: {
            once: "Jednorazovo",
            day: "Deň",
            week: "Týždeň",
            two_weeks: "Druhý týždeň",
            month: "Mesiac",
            quarter: "Kvartál",
            half_a_year: "Polrok",
            year: "Rok",
        },
        order_status: {
            planned_one_time: "Plánovaná X",
            planned: "Plánovaná",
            executed: "Vykonaná",
            checked: "Skontrolovaná",
            completed: "Ukončená",
            canceled: "Zrušená",
            missed: "Vynechaná",
        },
        payment_method: {
            cash: "Hotovosť",
            card: "Karta",
            bank_transfer: "Bank. prevod",
        },
        order_bonus: {
            0: "0 %",
            25: "25 %",
            35: "35 %",
            50: "50 %",
            75: "75 %",
            100: "100 %",
        },
        activity_type: {
            base: "Základ",
            window: "Okná",
            laundry: "Práčovňa",
            commission: "Zákazka",
        },
        address: {
            title: "Sídlo",
            zip: "PSČ",
            city: "Mesto",
            state: "Štát",
            street: "Ulica",
            number: "Číslo",
        },
    },
    ra: {
        action: {
            create_item: "Vytvoriť"
        },
        auth: {
            username: "Emailová adresa",
        },
        notification: {
            http_error: 'Server odmietol túto požiadavku',
        },
        page: {
            list: '%{name}',
            invite: "",
            edit: 'Uprav %{name} #%{id}',
        },
        navigation: {
            skip_nav: "Preskočiť na obsah",
        }
    },
    /**** Resources ****/
    resources: {
        users: {
            name: "Používateľa |||| Používatelia",
            fields: {
                id: "Id",
                email: "Emailová adresa",
                firstName: "Krstné meno",
                lastName: "Priezvisko",
                role: "Používateľská rola",
                active: "Používateľ aktívny",
                employee: "Zamestnanec",
            }
        },
        contracts: {
            name: "Zmluvu |||| Zmluvy",
            validity: {
                name: "Platnosť",
                valid: "Platné",
                invalid: "Neplatné",
                all: "Všetky",
                validUntil: "Platnosť k dátumu",
                infinity: "Neurčito"
            },
            hasNotAttachments: "Žiadna príloha",
            hasNotDuties: "Žiadna služba pre túto zmluvu",
            filters: {
                dutyAddress: "Miesto výkonu",
                activity: "Úkon",
                hasAttachments: "Obsahuje prílohu",
                hasOrders: "Obsahuje objednávku",
                periodicity: "Periodicita",
                winterMaintenance: "Zimná údržba",
            },
            fields: {
                id: "Id",
                customer: {
                    id: "Id objednávateľa",
                    name: "Objednávateľ",
                    shortName: "Skratka objednávateľa",
                },
                contractor: {
                    id: "Id zhotoviteľa",
                    name: "Zhotoviteľ",
                    shortName: "Skratka zhotoviteľa",
                },
                attachmentsCount: "Počet príloh",
                activities: "Úkony",
                validFrom: "Platnosť od",
                validUntil: "Platnosť do",
                subject: "Predmet",
                price: "Cena",
                contractNumber: "Číslo zmluvy",
                subcontractNumber: "Dodatok",
                note: "Poznámka",
                winterMaintenance: "Zimná údržba",
                terminationReason: "Dôvod ukončenia",
                attachments: "Prílohy",
                duties: {
                    id: "Id služby",
                    title: "Služby",
                    name: "Názov",
                    address: {
                        id: "Id partnerovej adresy",
                        title: "Miesto výkonu",
                        address: {
                            id: "Id adresy",
                            zip: "PSČ",
                            city: "Mesto",
                            state: "Štát",
                            street: "Ulica",
                            number: "Číslo",
                        },
                        janitorName: "Meno domovníka",
                        janitorContact: "Kontakt na domovníka",
                        keyAmount: "Počet kľúčov originál",
                        keyCopyAmount: "Počet kľúčov kópia",
                        note: "Poznámka",
                    },
                    periodicity: "Periodicita",
                    startDate: "Počiatočný dátum",
                    activities: {
                        id: "Id úkonu",
                        title: "Úkony",
                        name: "Názov",
                        shortName: "Skratka",
                        minutes: "Čas v minútach",
                        time: "Čas",
                    },
                    activitiesDuration: "Úkony (spolu: %{sum})",
                }
            }
        },
        partners: {
            name: "Partnera |||| Partneri",
            fields: {
                id: "Id",
                name: "Názov",
                shortName: "Skratka",
                representative: "Konateľ",
                ico: "IČO",
                dic: "DIČ",
                bankName: "Bank. spoj.",
                bankAccount: "IBAN",
                phoneNumber: "Tel.č.",
                email: "Emailová adresa",
                note: "Poznámka",
                contractor: "Zhotoviteľ",
                address: {
                    id: "Id adresy",
                    name: "Adresa",
                    zip: "PSČ",
                    city: "Mesto",
                    state: "Štát",
                    street: "Ulica",
                    number: "Číslo",
                },
                addresses: {
                    id: "Id partnerovej adresy",
                    name: "Upratovacie adresy",
                    empty: "Žiadna upratovacia adresa",
                    address: {
                        id: "Id adresy",
                        name: "Adresa",
                        zip: "PSČ",
                        city: "Mesto",
                        state: "Štát",
                        street: "Ulica",
                        number: "Číslo",
                    },
                    janitorName: "Meno domovníka",
                    janitorContact: "Kontakt na domovníka",
                    keyAmount: "Počet kľúčov originál",
                    keyCopyAmount: "Počet kľúčov kópia",
                    note: "Poznámka",
                },
                janitorTitle: "Domovník",
                janitorName: "Meno",
                janitorContact: "Kontakt",
                keyAmount: "Počet kľúčov originál",
                keyCopyAmount: "Počet kľúčov kópia",
            }
        },
        activities: {
            name: "Úkon |||| Úkony",
            fields: {
                id: "Id",
                name: "Názov",
                shortName: "Skratka",
                minutes: "Čas v minútach",
                time: "Čas",
                type: "Typ",
            },
        },
        employees: {
            name: "Zamestnanca |||| Zamestnanci",
            fields: {
                id: "Id",
                firstName: "Krstné meno",
                lastName: "Priezvisko",
                address: {
                    id: "Id adresy",
                    name: "Bydlisko",
                    zip: "PSČ",
                    city: "Mesto",
                    state: "Štát",
                    street: "Ulica",
                    number: "Číslo",
                },
                baseHourlyRate: "Základná mzda (hod.)",
                windowHourlyRate: "Mzda okná (hod.)",
                laundryHourlyRate: "Mzda práčovňa (hod.)",
                commissionHourlyRate: "Mzda zákazka (hod.)",
                bonusHourlyRate: "Príplatková mzda (hod.)",
                specification: "Špecifikácia",
                active: "Aktívny",
            }
        },
        orders: {
            name: "Objednávku |||| Objednávky",
            list: "List",
            calendar: "Kalendár",
            fields: {
                id: "Id",
                assignee: "Zamestnanec",
                code: "Kód objednávky",
                orderNumber: "Číslo objednávky",
                created: "Dátum vytvorenia",
                realizationDate: "Realizačný dátum",
                status: "Stav",
                bonus: "Príplatok",
                overrideMinutes: "Čas v minútach",
                overrideActivityType: "Typ úkonu",
                duration: "Trvanie",
                statusTransitions: {
                    by: "Zmenená používateľom",
                    from: "Zmenená z",
                    timeStamp: "Dátum zmeny",
                    to: "Zmenená na",
                },
                duty: {
                    name: "Služba",
                    address: {
                        address: "Miesto výkonu",
                    }
                },
                oneTimeDuty: {
                    title: "Jednorázová služba",
                    time: "Čas",
                    note: "Poznámka",
                    name: "Názov",
                    paymentMethod: "Spôsob platby",
                    contact: "Kontakt",
                    price: "Cena",
                    attachments: "Prílohy",
                    address: "Miesto výkonu",
                    realizationDate: "Realizačý dátum a čas",
                },
                updated: "Dátum aktualizácie",
                noAssignee: "Nepriradený",
                time: "Trvanie",
                // exporter
                zip: "PSČ",
                city: "Mesto",
                state: "Štát",
                street: "Ulica",
                number: "Číslo",
                assigneeId: "Id zamestnanca",
                assigneeFirstName: "Zamestnanec krstné meno",
                assigneeLastName: "Zamestnanec priezvisko",
                activities: "Úkony",
                hours: "Trvanie v hodinách",
                baseHourlyRate: "Základná mzda (hod.)",
                windowHourlyRate: "Mzda okná (hod.)",
                laundryHourlyRate: "Mzda práčovňa (hod.)",
                commissionHourlyRate: "Mzda zákazka (hod.)",
                bonusHourlyRate: "Príplatková mzda (hod.)",
                bonusPercentage: "Príplatok v %",
                price: "Cena objednávky",
            },
            sum: "Spolu",
            hasNotAttachments: "Žiadna príloha",
            filters: {
                realizationDateFrom: "Realizačý dátum od",
                realizationDateUntil: "Realizačý dátum do",
                assigneeName: "Meno zamestnanca",
                dutyAddress: "Miesto výkonu",
                contractNumber: "Číslo zmluvy",
                unassigned: "Nepriradený",
                periodicity: "Periodicita",
                dutyId: "Id služby",
                oneTime: "Jednorázové",
                bonus: "Príplatok",
                activityIds: "Úkony",
                activityType: "Typ úkonu",
            },
        },
        duties: {
            name: "Službu |||| Služby",
            fields: {
                id: "Id",
                name: "Názov",
                startDate: "Počiatočný dátum",
                address: {
                    address: "Miesto výkonu",
                },
                periodicity: "Periodicita",
                activities: "Úkony",
                activitiesDuration: "Úkony (spolu: %{sum})",
                ordersCount: "Počet objednávok",
                contractNumber: "Číslo zmluvy",
                contractSubject: "Predmet zmluvy",
                minOrderDate: "Dátum prvej objednávky",
                maxOrderDate: "Dátum poslednej objednávky",
                // exporter
                zip: "PSČ",
                city: "Mesto",
                state: "Štát",
                street: "Ulica",
                number: "Číslo",
                hours: "Úkony spolu",
            },
            filters: {
                dutyAddress: "Miesto výkonu",
                activityIds: "Úkony",
                // minOrderDate: "Dátum prvej objednávky od",
                maxOrderDate: "Negenerované od",
                activityType: "Typ úkonu",
            },
        },
        validation: {
            required: "Tento záznam je povinný",
            length: "Tento záznam musí mať %{size} znakov",
            number: "Tento záznam musí byť číslo",
            positive_number: "Tento záznam musí byť kladné číslo",
            integer: "Tento záznam musí byť celé číslo",
            without_space: "Tento záznam nemôže obsahovať medzeru",
            email: "Povinná platná emailová adresa",
            password: "Heslo musí byť dlhšie ako 8 znakov",
            password_repeat: "Heslá musia byť rovnaké a dlhšie ako 8 znakov",
            title_required: "Názov je povinný",
            media_file_required: "Je potrebné nahrať súbor",
            invalid_email: "Neplatná emailová adresa",
            invalid_phone: "Neplatné telefónne číslo",
            invalid_url: "Neplatná webová adresa",
            from_after_until: "Platnosť nemôže skončiť skôr ako začala",
            start_date_before_validity_from: "Počiatočný dátum je skôr ako platnosť zmluvy",
            missing_customer: "Chýba objednávateľ"
        },
        fields: {
            business_title: "Údaje o firme",
            general_title: "Základné údaje",
            bank_info_title: "Údaje o banke",
        },
    }
};
