export const BASE = "BASE";
export const WINDOW = "WINDOW";
export const LAUNDRY = "LAUNDRY";
export const COMMISSION = "COMMISSION";

export const ACTIVITY_TYPE = [
    {id: BASE, name: "app.activity_type.base"},
    {id: WINDOW, name: "app.activity_type.window"},
    {id: LAUNDRY, name: "app.activity_type.laundry"},
    {id: COMMISSION, name: "app.activity_type.commission"},
]