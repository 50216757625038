export const partnerAddressAdditionalInfo = (translate, {janitorName, janitorContact, keyAmount, keyCopyAmount, note}) => {
    const items = [];
    const title = translate('resources.partners.fields.janitorTitle')
    if (janitorName || janitorContact)
        items.push(`${title}: ${janitorName || ''} ${janitorContact || ''}`.trim());

    if (keyAmount)
        items.push(`${translate('resources.partners.fields.keyAmount')}: ${keyAmount}`);

    if(keyCopyAmount)
        items.push(`${translate('resources.partners.fields.keyCopyAmount')}: ${keyCopyAmount}`);

    if(note)
        items.push(`${translate('resources.partners.fields.note')}: ${note}`);

    return `${items.join(', ')}`;
};