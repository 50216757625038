import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import slovakMessages from 'ra-language-slovak';

import slovakDomainMessages from './sk';
import englishDomainMessages from './en';

const _ = require('lodash');

const messages = {
    sk: _.merge(slovakMessages, slovakDomainMessages),
    en: _.merge(englishMessages, englishDomainMessages),
};
export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'sk');
