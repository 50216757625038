export const ROLE_USER = 'USER'
export const ROLE_ADMIN = 'ADMIN'
export const ROLE_SUPER_ADMIN = 'SUPER_ADMIN'

export const availableRoles = (permissions) => {
    return [
        {id: ROLE_USER, name: 'app.users.roles.user'},
        {id: ROLE_ADMIN, name: 'app.users.roles.admin'},
        {id: ROLE_SUPER_ADMIN, name: 'app.users.roles.super_admin', disabled: permissions !== ROLE_SUPER_ADMIN}
    ];
}

export const ROLES = [
    {id: ROLE_USER, name: 'app.users.roles.user'},
    {id: ROLE_ADMIN, name: 'app.users.roles.admin'},
    {id: ROLE_SUPER_ADMIN, name: 'app.users.roles.super_admin'}
];


