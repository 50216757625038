import React from 'react';
import {Edit} from 'react-admin';
import ActivityForm from "./form";

export const ActivityEdit = ({permissions, ...props}) => {
    return <Edit mutationMode='pessimistic' {...props}>
        <ActivityForm permissions={permissions} redirect='/activities'/>
    </Edit>
};

export default ActivityEdit;