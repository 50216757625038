import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton,
    useTranslate,
    Pagination,
    BooleanField,
} from 'react-admin';
import {AddressField, ListActions, ListBulkActions} from "../../components";
import {EmployeesExporter} from "../../data/export";


const EmployeeList = ({permissions, ...props}) => {
    const translate = useTranslate();
    return <List
        {...props}
        actions={<ListActions/>}
        bulkActionButtons={<ListBulkActions permissions={permissions}/>}
        exporter={EmployeesExporter(translate)}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50 ,100]} />}
        perPage={50}
    >
        <Datagrid>
            {/*<TextField source="id"/>*/}
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            <AddressField source='address' label='resources.employees.fields.address.name'/>
            <TextField source="specification"/>
            <FunctionField
                label="resources.employees.fields.baseHourlyRate"
                source="hourlyRates"
                render={r => r?.hourlyRates?.BASE}
                sortable={false}
            />
            <FunctionField
                label="resources.employees.fields.windowHourlyRate"
                source="hourlyRates"
                render={r => r?.hourlyRates?.WINDOW}
                sortable={false}
            />
            <FunctionField
                label="resources.employees.fields.laundryHourlyRate"
                source="hourlyRates"
                render={r => r?.hourlyRates?.LAUNDRY}
                sortable={false}
            />
            <FunctionField
                label="resources.employees.fields.commissionHourlyRate"
                source="hourlyRates"
                render={r => r?.hourlyRates?.COMMISSION}
                sortable={false}
            />
            <TextField source="bonusHourlyRate" sortable={false}/>
            <BooleanField source="active"/>
            <EditButton basePath="/employees"/>
        </Datagrid>
    </List>
};

export default EmployeeList;