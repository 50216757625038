import React, {Fragment} from "react";
import {
    BulkDeleteButton,
    BulkExportButton,
    CreateButton,
    ExportButton,
    FilterButton,
    TopToolbar,
} from "react-admin";
import {ROLE_USER} from "../resources/user/constants";
import {
    GenerateOrdersButton,
    EditEmployeeButton,
    EditOrderStatusButton,
    EditRealizationDateButton,
    EditOrderBonusButton,
} from "./";

export const ListBulkActions = props => (
    <Fragment>
        {['contracts', 'duties'].includes(props.resource) && <GenerateOrdersButton {...props}/>}
        {props.resource === 'orders' && <EditOrderBonusButton {...props}/>}
        {props.resource === 'orders' && <EditRealizationDateButton {...props}/>}
        {props.resource === 'orders' && <EditOrderStatusButton {...props}/>}
        {props.resource === 'orders' && <EditEmployeeButton {...props}/>}
        <BulkExportButton {...props} />
        {/*{(props.permissions !== ROLE_USER || props.remove) && <BulkDeleteButton {...props} undoable={false}/>}*/}
        {((!props.disableRemove && props.permissions !== ROLE_USER) || props.remove) && <BulkDeleteButton {...props} undoable={false}/>}
    </Fragment>
);

export const ListActions = ({resource, filter, hasCreate}) => (
    <TopToolbar>
        {filter && <FilterButton style={{minWidth: '135px'}}/>}
        {/*{resource === 'contracts' && <GenerateOrdersButton generateAll/>}*/}
        {hasCreate && <CreateButton/>}
        <ExportButton maxResults={100000}/>
    </TopToolbar>
);
