import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleListItemExpand } from "react-admin";
import moment from "moment";

export const safeOptionText = callback => record => {
    return record && (record.id === '@@ra-create' ? record.name : callback(record));
}

export const useExpandOnlyOne = (resource, location) => {
    const expanded = useSelector(
        (state) => state.admin.resources[resource].list.expanded
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (expanded.length > 1)
            dispatch(toggleListItemExpand(resource, expanded[0]));
    }, [expanded, dispatch, resource]);

    useEffect(() => {
        dispatch(toggleListItemExpand(resource, null));
    }, [location, dispatch, resource]);
};

const toUtc = dt => {
    return moment(dt).utc(true).toISOString()
};
export const toUtcFix = dt => {
    return toUtc(toUtc(dt));
};

export const dateToUtcString = value => {
    const date = moment(value);
    if (!date.isValid() || date.year() < 1000) {
        return value;
    }
    return date.utc(true).toISOString();
};