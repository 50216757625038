import React, {Fragment, useState} from 'react'
import RealizationDateIcon from '@mui/icons-material/CalendarToday';
import {
    Button,
    useNotify,
    useDataProvider,
    Confirm,
    useRefresh,
    SimpleForm,
    DateInput,
    setListSelectedIds,
} from 'react-admin';
import {useDispatch} from "react-redux";
import {toUtcFix} from "./utils";


export const EditRealizationDateButton = ({selectedIds}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isExecuting, setExecuting] = useState(false);
    const [realizationDate, setRealizationDate] = useState();

    const handleAction = e => {
        e.stopPropagation();
        if (!selectedIds || selectedIds.length === 0) {
            notify('app.message.nothing_selected', 'warning');
            return;
        }
        if (!realizationDate) return
        setExecuting(true);

        dataProvider
            .updateRealizationDate({orderIds: selectedIds, realizationDate: realizationDate})
            .then(() => {
                notify('app.message.updated_realization_date', 'info');
                refresh();
                handleDialogClose();
                dispatch(setListSelectedIds('orders', []));
            })
            .catch(() => {
                notify('app.message.updated_realization_date_fail', 'warning')
            })
            .finally(() => {
                setExecuting(false);
                setRealizationDate(undefined);
            });
    };

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Button onClick={handleClick} label='app.message.update_realization_date'>
            <RealizationDateIcon/>
        </Button>
        <Confirm
            isOpen={isOpen}
            loading={isExecuting}
            title='app.message.update_realization_date_title'
            content={
                <SimpleForm toolbar={false}>
                    <DateInput
                        source='realizationDate'
                        label='resources.orders.fields.realizationDate'
                        onChange={e => setRealizationDate(toUtcFix(e?.target?.value))}
                    />
                </SimpleForm>
            }
            onConfirm={handleAction}
            onClose={handleDialogClose}
        />
    </Fragment>
};
