import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    EditButton,
    useTranslate,
    Pagination,
    SelectField,
} from 'react-admin';
import {prettyTimeString} from "./utils";
import {ListActions, ListBulkActions} from "../../components";
import {Exporter} from "../../data/export";
import {ACTIVITY_TYPE} from "./constants";


const ActivityList = ({permissions, ...props}) => {
    const translate = useTranslate();
    return <List
        {...props}
        actions={<ListActions/>}
        bulkActionButtons={<ListBulkActions remove permissions={permissions}/>}
        exporter={Exporter(translate, 'activities')}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50 ,100]} />}
        perPage={50}
    >
        <Datagrid>
            {/*<TextField source="id"/>*/}
            <TextField source="name"/>
            <TextField source="shortName"/>
            <FunctionField
                source="minutes"
                render={r => r && prettyTimeString(r.minutes)}
                label='resources.activities.fields.time'
            />
            <SelectField source="type" choices={ACTIVITY_TYPE}/>
            <EditButton basePath="/activities"/>
        </Datagrid>
    </List>
};

export default ActivityList;