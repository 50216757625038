import React from 'react';
import {Link} from "@mui/material";
import {useDataProvider} from 'react-admin';

export const LazyFileField = ({record}) => {
    const dataProvider = useDataProvider();
    const handleOnClick = e => {
        e.preventDefault();

        if (record?.rawFile) {
            if (!record?.rawFile) return;
            const newWindow = window.open(URL.createObjectURL(record?.rawFile), '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            dataProvider.getOne('files', {id: record && record.id}).then(file => {
                if (!file || !file.data) return;
                const newWindow = window.open(file.data.src, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            });
        }
    }
    const title = record?.title || record?.rawFile?.name;
    return <Link href="#" onClick={handleOnClick}>{title}</Link>
}