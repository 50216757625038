import React from 'react';
import {Create} from 'react-admin';
import ActivityForm from "./form";

export const ActivityCreate = ({permissions, ...props}) => {
    return <Create {...props}>
        <ActivityForm permissions={permissions} redirect='/activities'/>
    </Create>
};

export default ActivityCreate;