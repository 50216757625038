import {DeleteButton, SaveButton, Toolbar as RAToolbar} from "react-admin";
import {ROLE_USER} from "../resources/user/constants";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

export const Toolbar = ({permissions, disableRemove, remove, ...props}) =>
    <RAToolbar {...props} classes={useStyles()}>
        <SaveButton/>
        {((!disableRemove && permissions !== ROLE_USER) || remove) && <DeleteButton mutationMode="pessimistic"/>}
    </RAToolbar>
