import {AutocompleteArrayInput as RAAutocompleteArrayInput} from "react-admin";
import React from "react";
import {Tooltip} from "./";


export const AutocompleteArrayInput = ({tooltip, ...props}) => {
    const {choices} = props;
    const tooltipTitle = tooltip instanceof Function ? tooltip(choices) : tooltip;
    return <Tooltip title={tooltipTitle}>
        <RAAutocompleteArrayInput {...props}/>
    </Tooltip>

};
