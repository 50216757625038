import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    SelectField,
    useTranslate,
    Pagination,
} from 'react-admin';
import {ROLES} from './constants';
import {ListBulkActions, ListActions} from "../../components";
import {UsersExporter} from "../../data/export";


const UserList = ({permissions, ...props}) => {
    const translate = useTranslate();

    return <List
        {...props}
        actions={<ListActions/>}
        bulkActionButtons={<ListBulkActions permissions={permissions}/>}
        exporter={UsersExporter(translate)}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50 ,100]} />}
        perPage={50}
    >
        <Datagrid>
            {/*<TextField source="id"/>*/}
            <TextField source="email"/>
            <TextField source="firstName"/>
            <TextField source="lastName"/>
            <SelectField source="role" choices={ROLES}/>
            <BooleanField source="active"/>
            <EditButton basePath="/users"/>
        </Datagrid>
    </List>
};

export default UserList;