import React from 'react';
import InvitationPage from './invitation';
import PasswordResetPage from './passwordReset';
import LoginPage from './login';
import qs from 'qs'


const Auth = (props) => {
    const params = qs.parse(props?.location?.search, {ignoreQueryPrefix: true});

    if (params.hasOwnProperty("invitationToken")) {
        return <InvitationPage invitationToken={params.invitationToken} email={params.email}/>;
    }

    if (params.hasOwnProperty("passwordResetToken")) {
        return <PasswordResetPage passwordResetToken={params.passwordResetToken} email={params.email}/>;
    }

    return <LoginPage/>;
};

const AuthPage = props => (
    <div>
        <Auth {...props}/>
        <img src="ferdo_text.png" title="ferdo_text" alt="ferdo_text" style={{
            position: 'fixed',
            left: '50px',
            top: '50px',
            width: '400px',
        }} />
        <img src="ferdo_logo.png" title="ferdo_logo" alt="ferdo_logo" style={{
            position: 'fixed',
            right: '50px',
            bottom: '50px',
            width: '400px',
        }} />
    </div>
);

export default AuthPage;
