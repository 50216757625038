export const EXECUTED = "EXECUTED"
export const PLANNED = "PLANNED"
export const CHECKED = "CHECKED"
export const CANCELED = "CANCELED"
export const MISSED = "MISSED"
export const PLANNED_ONE_TIME = {id: PLANNED, name: "app.order_status.planned_one_time", color: "rgb(13,176,9)"};

export const ORDER_STATUS = [
    {id: PLANNED, name: "app.order_status.planned", color: "rgb(27,190,255)"},
    {id: EXECUTED, name: "app.order_status.executed", color: "rgb(177,174,73)"},
    {id: CHECKED, name: "app.order_status.checked", color: "rgb(181,115,203)"},
    {id: CANCELED, name: "app.order_status.canceled", color: "rgb(122,118,118)"},
    {id: MISSED, name: "app.order_status.missed", color: "rgb(227,91,55)"},
]

export const PAYMENT_METHOD = [
    {id: "BANK_TRANSFER", name: "app.payment_method.bank_transfer"},
    {id: "CASH", name: "app.payment_method.cash"},
    {id: "CARD", name: "app.payment_method.card"},
]

export const NO_ORDER_BONUS = 0;
export const ORDER_BONUSES = [
    {id: NO_ORDER_BONUS, name: "app.order_bonus.0"},
    {id: 25, name: "app.order_bonus.25"},
    {id: 35, name: "app.order_bonus.35"},
    {id: 50, name: "app.order_bonus.50"},
    {id: 75, name: "app.order_bonus.75"},
    {id: 100, name: "app.order_bonus.100"},
]

export const orderStatusName = (translate, value) => {
    if (!value) return '';

    const orderStatus = ORDER_STATUS.find(o => value.status ? o.id === value.status : o.id === value);
    if (!orderStatus) return '';

    return translate(orderStatus.name)
}