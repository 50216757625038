import React from 'react';
import {Create} from 'react-admin';
import EmployeeForm from "./form";

export const EmployeeCreate = ({permissions, ...props}) => {
    return <Create
        transform={employee => ({
            ...employee,
            hourlyRates: {
                "BASE": employee?.baseHourlyRate,
                "WINDOW": employee?.windowHourlyRate,
                "LAUNDRY": employee?.laundryHourlyRate,
                "COMMISSION": employee?.commissionHourlyRate,
            }
        })}
        {...props}
    >
        <EmployeeForm permissions={permissions} redirect='/employees'/>
    </Create>
};

export default EmployeeCreate;