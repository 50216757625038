import {
    NumberInput,
    SimpleForm,
    SelectInput,
    TextInput,
} from "react-admin";
import {positiveNumber, required} from "../../data/validation";
import React from "react";
import {Toolbar} from "../../components";
import {ACTIVITY_TYPE} from "./constants";


const ActivityForm = ({resource, save, permissions}) => {
    return (
        <SimpleForm
            save={save}
            toolbar={<Toolbar remove permissions={permissions}/>}
        >
            <TextInput
                resource={resource}
                source="shortName"
                required
                validate={required}
            />
            <TextInput
                resource={resource}
                fullWidth
                source="name"
                required
                validate={required}
            />
            <NumberInput
                resource={resource}
                source="minutes"
                min={0}
                validate={positiveNumber}
            />
            <SelectInput
                resources={resource}
                label="resources.activities.fields.type"
                source="type"
                choices={ACTIVITY_TYPE}
                required
                validate={required}
            />
        </SimpleForm>
    );
}

export default ActivityForm;