import React, {Fragment} from 'react'
import {CloneButton as RACloneButton} from 'react-admin'
import lodash from 'lodash'


export const CloneButton = ({record, omitFields, showFn, ...props}) => {
    const newRecord = omitFields ? lodash.omit(record, omitFields) : record;
    if (showFn) {
        if (showFn(record)) {
            return <RACloneButton record={newRecord} {...props}/>
        } else {
          return <Fragment/>
        }
    }
    return <RACloneButton record={newRecord} {...props}/>
};