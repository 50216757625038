import React from "react";
import {
    DateField,
    Labeled,
    TextField,
    useTranslate,
    SelectField,
    TextInput,
    SelectInput,
    FileInput,
} from "react-admin";
import {CustomAccordion} from "./Accordion";
import {RaBox} from "ra-compact-ui";
import {AddressField, AddressInput} from "./Address";
import {DUTY_PERIODICITY, periodicityName} from "../resources/contract/constants";
import {ActivitiesField} from "./ActivityField";
import {makeStyles} from "@material-ui/core";
import lodash from 'lodash';
import {PAYMENT_METHOD} from "../resources/order/constants";
import {positiveNumber} from "../data/validation";
import {LazyFileField} from "./LazyFileField";
import RichTextInput from "ra-input-rich-text";
import {groupBy} from "../data/utils";


const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
        },
        duties: {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        },
        marginDense: {
            marginRight: '10px',
        },
    },
);


export const DutyField = ({record, source}) => {
    const classes = useStyles();
    const duty = source ? lodash.get(record, source) : record;

    return <CustomAccordion
        title={
            <RaBox>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.address.title">
                    <AddressField source="address.address" record={duty}/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.name">
                    <TextField record={duty} source="name"/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.periodicity">
                    <SelectField record={duty} source="periodicity" choices={DUTY_PERIODICITY}/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.startDate">
                    <DateField record={duty} source="startDate"/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.address.janitorName">
                    <TextField record={duty} source="address.janitorName"/>
                </Labeled>
                <Labeled className={classes.marginDense}
                         label="resources.contracts.fields.duties.address.janitorContact">
                    <TextField record={duty} source="address.janitorContact"/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.address.keyAmount">
                    <TextField record={duty} source="address.keyAmount"/>
                </Labeled>
                <Labeled className={classes.marginDense}
                         label="resources.contracts.fields.duties.address.keyCopyAmount">
                    <TextField record={duty} source="address.keyCopyAmount"/>
                </Labeled>
                <Labeled className={classes.marginDense} label="resources.contracts.fields.duties.address.note">
                    <TextField record={duty} source="address.note"/>
                </Labeled>
            </RaBox>
        }
    >
        <ActivitiesField record={duty} source="activities"/>
    </CustomAccordion>
};

export const DutiesField = ({record}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const duties = record && record.duties ? groupBy(record.duties, 'periodicity') : undefined

    return <Labeled
        label='resources.contracts.fields.duties.title'
        className={classes.duties}
    >
        <RaBox>
            {duties && !lodash.isEmpty(duties)
                ? Object.keys(duties).map(periodicity => <CustomAccordion
                    title={<span>{periodicityName(translate, periodicity)}</span>}
                    className={classes.root}
                    detailsClassName={classes.details}
                >
                    {duties[periodicity].map(record => <DutyField record={record}/>)}
                </CustomAccordion>)
                : <span>{translate('resources.contracts.hasNotDuties')}</span>}
        </RaBox>
    </Labeled>
};

export const OneTimeDutyInput = props => (
    <Labeled fullWidth label="resources.orders.fields.oneTimeDuty.title">
        <RaBox display="flex">
            <RaBox flex={2} display="flex" flexDirection="column" marginRight='10px'>
                <RaBox display="flex" fillWidth>
                    <TextInput
                        source="oneTimeDuty.name"
                        label="resources.orders.fields.oneTimeDuty.name"
                        style={{marginRight: '10px'}}
                    />
                    <TextInput
                        source="oneTimeDuty.contact"
                        label="resources.orders.fields.oneTimeDuty.contact"
                        style={{marginRight: '10px'}}
                    />
                    <TextInput
                        source="oneTimeDuty.price"
                        label="resources.orders.fields.oneTimeDuty.price"
                        style={{marginRight: '10px'}}
                        validate={positiveNumber}
                        parse={value => value && value.replace(",", ".")}
                    />
                    <SelectInput
                        source="oneTimeDuty.paymentMethod"
                        label="resources.orders.fields.oneTimeDuty.paymentMethod"
                        choices={PAYMENT_METHOD}
                        style={{marginRight: '10px'}}
                    />
                </RaBox>

                <RaBox>
                    <AddressInput
                        source='oneTimeDuty.address'
                        label='resources.orders.fields.oneTimeDuty.address'
                        defaultValues={{zip: '08001', city: 'Prešov', state: 'Slovensko'}}
                        style={{marginRight: '10px'}}
                    />
                </RaBox>
            </RaBox>
            <RaBox flex={1} display="flex" flexDirection="column" marginRight='10px'>
                <RichTextInput
                    source="oneTimeDuty.note"
                    label='resources.orders.fields.oneTimeDuty.note'
                />
                <FileInput
                    source="oneTimeDuty.attachments"
                    label="resources.orders.fields.oneTimeDuty.attachments"
                    multiple
                    maxSize={5000000 /*5 MB*/}
                    parse={files => files && files.map(f => f instanceof File ? {
                        id: null,
                        title: f.name,
                        rawFile: f
                    } : f)}
                >
                    <LazyFileField/>
                </FileInput>
            </RaBox>
        </RaBox>
    </Labeled>
);
