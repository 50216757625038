import React from 'react';
import {
    Layout,
    // , AppBar, UserMenu, MenuItemLink, withTranslate
} from 'react-admin';
// import UserIcon from '@material-ui/icons/Person';
import {connect} from 'react-redux';
import defaultTheme from "./styles/Themes";


// const CustomUserMenu = withTranslate(({translate, ...props}) => (
//     <UserMenu {...props}>
//         <MenuItemLink
//             to='#'
//             primaryText={localStorage.getItem('email')}
//             leftIcon={<UserIcon/>}
//         />
//     </UserMenu>
// ));

// const CustomAppBar = (props) => (
//     <AppBar {...props} userMenu={<CustomUserMenu/>} />
// );

const CustomLayout = (props) => (
    <Layout /*appBar={CustomAppBar}*/ {...props}/>
);

// export default CustomLayout;
export default connect(
    state => ({
        theme: defaultTheme,
    }),
    {}
)(CustomLayout);