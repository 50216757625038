import React from "react";
import {ORDER_STATUS, PLANNED_ONE_TIME} from "../constants";
import {useTranslate} from "react-admin";
import {makeStyles, useMediaQuery} from "@material-ui/core";


const useStyles = makeStyles({
    legendRoot: {
        position: 'absolute',
        right: '4px',
        top: '60px',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        color: 'white'
    },
    legendItem: {
        marginRight: '20px',
        marginTop: '5px',
        paddingLeft: '3px',
        width: '100px',
        borderRadius: '3px',
    },
    legendRootMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'white',
    },
});

export const Legend = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <div className={isSmall ? classes.legendRootMobile : classes.legendRoot}>
        <div className={classes.legendItem} style={{backgroundColor: PLANNED_ONE_TIME.color}}>
            {translate(PLANNED_ONE_TIME.name)}
        </div>
        {ORDER_STATUS.map(o =>
            <div className={classes.legendItem} style={{backgroundColor: o.color}}>
                {translate(o.name)}
            </div>
        )}
    </div>
};