import React, {cloneElement} from 'react';
import {
    useCreateSuggestionContext,
    useRefresh,
    useNotify,
    useUpdate,
    useCreate,
    useTranslate,
} from 'react-admin';
import {
    Dialog as MDialog,
    DialogContent,
    IconButton,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import {DialogTitle, Typography} from "@mui/material";
import {EditContextProvider, ResourceContextProvider, useEditController} from "ra-core";

const styles = theme => ({
    dialogPaper: {
        minHeight: '80%',
        maxHeight: '80%',
        minWidth: '80%',
        maxWidth: '80%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
        color: theme.palette.grey[500],
    },
});

export const Dialog = withStyles(styles)(({submit, resource, classes, form, record, title, setCreated = true}) => {
    const {onCancel, onCreate} = useCreateSuggestionContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    const handleSubmit = (values) => {
        // console.log(`Vytvaram aktivitu cez dialog, resource: ${resource} data: ${JSON.stringify(values)}`);
        return submit(
            {payload: {data: values}},
            {
                onSuccess: ({data}) => {
                    refresh();
                    if (setCreated) return onCreate(data);
                    else return onCreate(null);
                },
                onFailure: () => {
                    notify('ra.notification.http_error', 'warning');
                },
            }
        );
    };

    return (
        <MDialog
            open
            onClose={onCancel}
            classes={{paper: classes.dialogPaper}}
        >
            <DialogTitle>
                {title && <Typography>
                    {translate(title)}
                </Typography>}
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onCancel}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {cloneElement(form, {save: handleSubmit, resource: resource, record: record})}
            </DialogContent>
        </MDialog>
    );
});

export const CreateDialog = ({resource, form, title, setCreated}) => {
    const [create] = useCreate(resource);

    return (
        <Dialog
            submit={create}
            resource={resource}
            form={form}
            title={title}
            setCreated={setCreated}
        />
    );
};

export const EditDialog = (props) => {
    const controllerProps = useEditController(props);
    const {record} = controllerProps;
    const {resource, id, form, title, setCreated} = props;
    const [update] = useUpdate(resource, id)

    return (
        <ResourceContextProvider value={resource}>
            <EditContextProvider value={controllerProps}>
                <Dialog submit={update} resource={resource} form={form} record={record} title={title} setCreated={setCreated}/>
            </EditContextProvider>
        </ResourceContextProvider>
    );
};
