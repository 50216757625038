import React from "react";
import {Form} from "react-final-form";
import {Box} from "@material-ui/core";
import {AutocompleteInput, ReferenceInput, SelectInput, TextInput} from "react-admin";
import {ORDER_STATUS} from "../constants";
import {fullNameText} from "../../../components";
import {DUTY_PERIODICITY} from "../../contract/constants";


export const CalendarFilter = ({handleOnChange, initialValues}) => {

    const setNewValue = e => {
        const source = e?.target?.name?.split('.', 2)
        const value = e?.target?.value;
        if (source.length > 1 && value) {
            handleOnChange(source[0], {[source[1]]: value});
        } else {
            handleOnChange(source[0], value);
        }
    };

    return <Form
        onSubmit={() => true}
        render={() => (
            <form>
                <Box display="flex" alignItems="flex-end" mb={1}>
                    <Box component="span" mr={2}>
                        <SelectInput
                            source="status"
                            label="resources.orders.fields.status"
                            choices={ORDER_STATUS}
                            onChange={setNewValue}
                            allowEmpty
                            initialValue={initialValues?.status}
                        />
                    </Box>
                    <Box component="span" mr={2}>
                        <ReferenceInput
                            source="assignee"
                            reference='employees'
                            sort={{field: 'lastName', order: 'ASC'}}
                            label='resources.orders.fields.assignee'
                            filterToQuery={searchText => ({fullName: searchText})}
                            parse={value => value && ({id: value})}
                            format={value => value ? value.id : value}
                            allowEmpty
                            onChange={id => handleOnChange("assignee", id ? {id: id} : id)}
                            initialValue={initialValues?.assignee}
                        >
                            <AutocompleteInput
                                optionText={fullNameText('')}
                            />
                        </ReferenceInput>
                    </Box>
                    <Box component="span" mr={2}>
                        <TextInput
                            source="dutyAddress"
                            label="resources.orders.filters.dutyAddress"
                            onChange={setNewValue}
                            initialValue={initialValues?.dutyAddress}
                        />
                    </Box>
                    <Box component="span" mr={2}>
                        <SelectInput
                            source="duty.periodicity"
                            choices={DUTY_PERIODICITY}
                            label="resources.orders.filters.periodicity"
                            allowEmpty
                            onChange={setNewValue}
                            initialValue={initialValues?.duty?.periodicity}
                        />
                    </Box>
                </Box>
            </form>
        )}/>
};
