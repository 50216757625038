import {downloadCSV} from "react-admin"
import jsonExport from "jsonexport/dist";
import {convertToHours, sum, totalHours} from "../resources/activity/utils";
import moment from 'moment';
import {periodicityName} from "../resources/contract/constants";
import {CANCELED, MISSED, orderStatusName} from "../resources/order/constants";
import {BASE, WINDOW, LAUNDRY, COMMISSION} from "../resources/activity/constants";
import {groupBy} from "./utils";

const removeAttachments = array => {
    if (!array) return null;
    return array.map(obj => {
        if (obj.hasOwnProperty("attachments")) {
            delete obj.attachments;
        }
        return obj;
    })
}

export const ContractsExporter = translate => contracts => {
    const preparedRecords = contracts.map(c => ({
        ...c,
        customer: {
            id: c.customer.id,
            name: c.customer.name,
            shortName: c.customer.shortName
        },
        contractor: {
            id: c.contractor.id,
            name: c.contractor.name,
            shortName: c.contractor.shortName
        },
    }));

    Exporter(translate, 'contracts')(preparedRecords);
};

export const EmployeesExporter = translate => employees => {
    const preparedRecords = employees.map(e => ({
        ...e,
        address: {
            ...e?.address
        },
    }));

    Exporter(translate, 'employees')(preparedRecords);
};

export const UsersExporter = translate => users => {
    const preparedRecords = users.map(u => ({
        ...u,
        employee: `${u?.employee?.firstName || ''} ${u?.employee?.lastName || ''}`,
    }));

    Exporter(translate, 'users')(preparedRecords);
};

export const OrdersExporter = translate => orders => {

    const orderPrice = (hours, bonus, activityType, hourlyRates, bonusHourlyRate) => {
        const baseRate = hourlyRates?.BASE || 0
        const windowRate = hourlyRates?.WINDOW || 0
        const laundryRate = hourlyRates?.LAUNDRY || 0
        const commissionRate = hourlyRates?.COMMISSION || 0
        const bonusRate = bonusHourlyRate || 0

        switch (activityType) {
            case BASE:
                return hours * baseRate + hours * bonusRate * bonus / 100;
            case WINDOW:
                return hours * windowRate + hours * bonusRate * bonus / 100;
            case LAUNDRY:
                return hours * laundryRate + hours * bonusRate * bonus / 100;
            case COMMISSION:
                return hours * commissionRate + hours * bonusRate * bonus / 100;
            default:
                return hours * baseRate + hours * bonusRate * bonus / 100;
        }
    };

    const calculatePrice = (activities, {assignee, bonus, overrideMinutes, overrideActivityType}) => {
        if (!assignee)
            return 0

        if (overrideMinutes !== null) {
            const hours = convertToHours(overrideMinutes);
            return orderPrice(hours, bonus, overrideActivityType, assignee?.hourlyRates, assignee?.bonusHourlyRate)
        }

        if (!activities) {
            return 0;
        }

        return activities.map(a => {
            const hours = convertToHours(a.minutes);
            return orderPrice(hours, bonus, a.type, assignee?.hourlyRates, assignee?.bonusHourlyRate)
        }).reduce(sum, 0)
    }

    const preparedRecords = orders.map(o => {
        const duty = o?.duty || o?.oneTimeDuty
        const dutyAddress = o?.duty ? duty?.address?.address : duty?.address
        const activities = duty?.activities;
        const realizationDate = moment(o?.realizationDate).format('YYYY-MM-DD');
        const hours = o?.overrideMinutes ? convertToHours(o?.overrideMinutes) : totalHours(activities);
        return {
            id: o?.id,
            orderNumber: o?.orderNumber,
            code: o?.code,
            realizationDate: realizationDate,
            status: orderStatusName(translate, o?.status),
            statusRaw: o?.status,
            zip: dutyAddress?.zip,
            city: dutyAddress?.city,
            street: dutyAddress?.street,
            number: dutyAddress?.number,
            assigneeId: o?.assignee?.id,
            assigneeFirstName: o?.assignee?.firstName,
            assigneeLastName: o?.assignee?.lastName,
            activities: activities ? activities.map(a => a.shortName).join(",") : '',
            hours: hours,
            baseHourlyRate: o?.assignee?.hourlyRates?.BASE,
            windowHourlyRate: o?.assignee?.hourlyRates?.WINDOW,
            laundryHourlyRate: o?.assignee?.hourlyRates?.LAUNDRY,
            commissionHourlyRate: o?.assignee?.hourlyRates?.COMMISSION,
            bonusHourlyRate: o?.assignee?.bonusHourlyRate,
            bonusPercentage: o?.bonus,
            price: calculatePrice(activities, o),
        }
    });

    const sumArray = array => array.reduce((a, b) => a + b, 0);

    const skipStatuses = [CANCELED, MISSED]
    const grouped = groupBy(preparedRecords.filter(r => r.assigneeId), 'assigneeId');
    const totals = Object.keys(grouped).map(assigneeId => {
        if (grouped[assigneeId].length === 0) return {}
        const first = grouped[assigneeId][0];
        const ordersToPay = grouped[assigneeId].filter(i => !skipStatuses.includes(i.statusRaw))
        const hours = sumArray(ordersToPay.map(i => i.hours));
        const price = sumArray(ordersToPay.map(i => i.price));

        return {
            id: translate('resources.orders.sum'),
            assigneeId: assigneeId,
            assigneeFirstName: first?.assigneeFirstName,
            assigneeLastName: first?.assigneeLastName,
            hours: hours,
            baseHourlyRate: first?.baseHourlyRate,
            windowHourlyRate: first?.windowHourlyRate,
            laundryHourlyRate: first?.laundryHourlyRate,
            commissionHourlyRate: first?.commissionHourlyRate,
            bonusHourlyRate: first?.bonusHourlyRate,
            price: price
        }
    });

    const finalRecords = [...preparedRecords, ...totals]
        .map(r => {
            // remove columns
            delete r.statusRaw;
            return r;
        });

    Exporter(translate, 'orders')(finalRecords);
};


export const DutiesExporter = translate => duties => {
    const formatDate = date => {
        const momentDate = moment(date)
        return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : '';
    }

    const preparedRecords = duties.map(d => {
        const activities = d?.activities;
        const hours = totalHours(activities);
        return {
            id: d?.id,
            name: d?.name,
            startDate: formatDate(d?.startDate),
            periodicity: periodicityName(translate, d?.periodicity),
            zip: d?.address?.address?.zip,
            city: d?.address?.address?.city,
            street: d?.address?.address?.street,
            number: d?.address?.address?.number,
            minOrderDate: formatDate(d?.minOrderDate),
            maxOrderDate: formatDate(d?.maxOrderDate),
            ordersCount: d?.ordersCount,
            activities: activities ? activities.map(a => a.shortName).join(",") : '',
            hours: hours,
        }
    });

    Exporter(translate, 'duties')(preparedRecords);
};

export const Exporter = (translate, resource) => records => {
    jsonExport(removeAttachments(records), {
        fillGaps: true,
        mapHeaders: header => translate(`resources.${resource}.fields.${header}`)
    }, (err, csv) => {
        if (err) {
            console.log("Error exporting groups: " + JSON.stringify(err));
        } else {
            const BOM = '\uFEFF'
            downloadCSV(`${BOM} ${csv}`, translate(`resources.${resource}.name`, {smart_count: 2}))
        }
    });
}
