import {CompactForm, RaBox} from "ra-compact-ui";
import {
    ArrayInput,
    SimpleFormIterator,
    BooleanInput,
    Labeled,
    TextInput,
    FormDataConsumer,
} from "react-admin";
import {required, requiredLen8} from "../../data/validation";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {Toolbar, LabeledAddressInput, getAddressTitle, CustomAccordion} from "../../components";


const PartnerForm = ({permissions, disableRemove, resource, save, disableContractor}) => (
    <CompactForm
        layoutComponents={[RaBox]}
        save={save}
        toolbar={<Toolbar permissions={permissions} disableRemove={disableRemove}/>}
    >
        <RaBox display="flex" justifyContent="space-between">
            <Labeled label='resources.fields.general_title'>
                <span>
                    <TextInput source="shortName" fullWidth required validate={required} resource={resource}/>
                    <TextInput source="phoneNumber" fullWidth resource={resource}/>
                    <TextInput source="email" fullWidth resource={resource}/>
                    {disableContractor ?
                        <BooleanInput source="contractor" value={false} initialValue={false} resource={resource}/>
                        : <BooleanInput source="contractor" initialValue={false} resource={resource}/>}
                </span>
            </Labeled>

            <Labeled label='resources.fields.business_title' resource={resource}>
                <div>
                    <TextInput source="name" fullWidth required validate={required} resource={resource}/>
                    <TextInput source="ico" fullWidth required validate={requiredLen8} resource={resource}/>
                    <TextInput source="dic" fullWidth resource={resource}/>
                    <TextInput source="representative" fullWidth resource={resource}/>
                </div>
            </Labeled>

            <LabeledAddressInput
                source='address'
                required
                validate={required}
                resource={resource}
                defaultValues={{zip: '08001', city: 'Prešov', state: 'Slovensko'}}
            />

            <Labeled label='resources.fields.bank_info_title' resource={resource}>
                <div>
                    <TextInput source="bankName" fullWidth resource={resource}/>
                    <TextInput source="bankAccount" fullWidth resource={resource}/>
                </div>
            </Labeled>
        </RaBox>
        <RaBox>
            <RichTextInput resource={resource} source="note"/>
        </RaBox>
        <RaBox>
            <ArrayInput resource={resource} source='addresses' label='resources.partners.fields.addresses.name'>
                <SimpleFormIterator resource={resource} disableReordering>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => (
                            <CustomAccordion title={scopedFormData && getAddressTitle(scopedFormData.address)}>
                                <RaBox>
                                    <LabeledAddressInput
                                        source={getSource('address')}
                                        record={scopedFormData}
                                        label='resources.partners.fields.address.name'
                                        style={{marginRight: '10px'}}
                                        defaultValues={{zip: '08001', city: 'Prešov', state: 'Slovensko'}}
                                    />
                                    <Labeled label="resources.partners.fields.janitorTitle">
                                        <RaBox>
                                            <TextInput
                                                source={getSource('janitorName')}
                                                record={scopedFormData}
                                                label='resources.partners.fields.janitorName'
                                                style={{marginRight: '10px'}}
                                            />
                                            <TextInput
                                                source={getSource('janitorContact')}
                                                record={scopedFormData}
                                                label='resources.partners.fields.janitorContact'
                                                style={{marginRight: '10px'}}
                                            />
                                            <TextInput
                                                source={getSource('keyAmount')}
                                                record={scopedFormData}
                                                label='resources.partners.fields.keyAmount'
                                                style={{marginRight: '10px'}}
                                            />
                                            <TextInput
                                                source={getSource('keyCopyAmount')}
                                                record={scopedFormData}
                                                label='resources.partners.fields.keyCopyAmount'
                                                style={{marginRight: '10px'}}
                                            />
                                            <TextInput
                                                source={getSource('note')}
                                                record={scopedFormData}
                                                label='resources.partners.fields.note'
                                                style={{marginRight: '10px'}}
                                            />
                                        </RaBox>
                                    </Labeled>
                                </RaBox>
                            </CustomAccordion>
                        )}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </RaBox>
    </CompactForm>
);

export default PartnerForm;