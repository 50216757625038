import {
    TextInput,
    BooleanInput,
    Labeled,
    FormDataConsumer,
} from "react-admin";
import {positiveNumber, required} from "../../data/validation";
import React from "react";
import {LabeledAddressInput, Toolbar} from "../../components";
import {CompactForm, RaBox} from "ra-compact-ui";


const EmployeeForm = ({resource, save, permissions}) => {
    return (
        <CompactForm
            layoutComponents={[RaBox]}
            save={save}
            toolbar={<Toolbar permissions={permissions}/>}
        >
            <RaBox display="flex" justifyContent="space-between">
                <Labeled label='resources.fields.general_title'>
                <span>
                <TextInput
                    resource={resource}
                    source="firstName"
                    required
                    validate={required}
                />
                <TextInput
                    resource={resource}
                    source="lastName"
                    required
                    validate={required}
                />
                <TextInput
                    resource={resource}
                    source="specification"
                />
                <FormDataConsumer>
                 {({formData, ...rest}) =>
                     <TextInput
                         resource={resource}
                         source="baseHourlyRate"
                         min={0}
                         validate={positiveNumber}
                         initialValue={formData?.hourlyRates?.BASE}
                         {...rest}
                     />}
                </FormDataConsumer>
                <FormDataConsumer>
                 {({formData, ...rest}) =>
                     <TextInput
                         resource={resource}
                         source="windowHourlyRate"
                         min={0}
                         validate={positiveNumber}
                         initialValue={formData?.hourlyRates?.WINDOW}
                         {...rest}
                     />}
                </FormDataConsumer>
                <FormDataConsumer>
                 {({formData, ...rest}) =>
                     <TextInput
                         resource={resource}
                         source="laundryHourlyRate"
                         min={0}
                         validate={positiveNumber}
                         initialValue={formData?.hourlyRates?.LAUNDRY}
                         {...rest}
                     />}
                </FormDataConsumer>
                <FormDataConsumer>
                 {({formData, ...rest}) =>
                     <TextInput
                         resource={resource}
                         source="commissionHourlyRate"
                         min={0}
                         validate={positiveNumber}
                         initialValue={formData?.hourlyRates?.COMMISSION}
                         {...rest}
                     />}
                </FormDataConsumer>
                <TextInput
                    resource={resource}
                    source="bonusHourlyRate"
                    min={0}
                    validate={positiveNumber}
                />
                <BooleanInput
                    resource={resource}
                    source='active'
                    initialValue={true}
                    allowNull={false}
                />
                </span>
                </Labeled>
                <LabeledAddressInput
                    source='address'
                    resource={resource}
                    label='resources.employees.fields.address.name'
                />
                <div/>
                <div/>
            </RaBox>
        </CompactForm>
    );
}

export default EmployeeForm;