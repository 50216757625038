import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    EditButton,
    RichTextField,
    ArrayField,
    useTranslate, Pagination,
} from 'react-admin';
import {BoxedShowLayout, RaBox} from 'ra-compact-ui'
import {
    ListBulkActions,
    AddressField,
    AddressFields,
    ListActions,
    useExpandOnlyOne,
    SingleFieldList
} from "../../components";
import {Divider} from '@mui/material';
import {Exporter} from "../../data/export";
import {partnerAddressAdditionalInfo} from "./utils";


const AllFields = (props) => {
    const translate = useTranslate();

    return <BoxedShowLayout {...props}>
        <RaBox display="flex" justifyContent="space-between">
            <RaBox>
                <TextField source="shortName"/>
                <TextField source="phoneNumber"/>
                <TextField source="email"/>
                <BooleanField source="contractor"/>
            </RaBox>
            <RaBox>
                <TextField source="name"/>
                <TextField source="ico"/>
                <TextField source="dic"/>
                <TextField source="representative"/>
            </RaBox>
            <RaBox>
                {AddressFields}
            </RaBox>
            <RaBox>
                <TextField source="bankName"/>
                <TextField source="bankAccount"/>
                <RichTextField source="note"/>
            </RaBox>
            <RaBox>
                <div/>
            </RaBox>
        </RaBox>
        <Divider/>
        <ArrayField source='addresses' label='resources.partners.fields.addresses.name'>
            <SingleFieldList emptyText={translate('resources.partners.fields.addresses.empty')}>
                <PartnerAddressField/>
            </SingleFieldList>
        </ArrayField>
    </BoxedShowLayout>
};


const PartnerAddressField = props => {
    const {record} = props;
    const translate = useTranslate();

    return (
        <RaBox>
            <AddressField source='address' {...props}/>
            <span style={{marginLeft: '20px', color: 'rgba(0, 0, 0, 0.47)'}}>{partnerAddressAdditionalInfo(translate, record)}</span>
        </RaBox>
    )
};

const PartnerList = ({permissions, ...props}) => {
    const translate = useTranslate();
    useExpandOnlyOne(props.resource, props.location);

    return <List
        {...props}
        actions={<ListActions/>}
        bulkActionButtons={<ListBulkActions permissions={permissions}/>}
        exporter={Exporter(translate, 'partners')}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>}
        perPage={50}
    >
        <Datagrid
            rowClick="expand"
            expand={<AllFields {...props}/>}
        >
            {/*<TextField source="id"/>*/}
            <TextField source="shortName"/>
            <AddressField source="address" label='app.address.title'/>
            <TextField source="ico"/>
            <BooleanField source="contractor"/>
            <EditButton basePath="/partners"/>
        </Datagrid>
    </List>
};

export default PartnerList;