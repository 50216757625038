import React, {Fragment, useState} from 'react'
import StatusIcon from '@mui/icons-material/Task';
import {
    Button,
    useNotify,
    useDataProvider,
    Confirm,
    useRefresh,
    SimpleForm,
    SelectInput, setListSelectedIds,
} from 'react-admin';
import {ORDER_STATUS} from "../resources/order/constants";
import {useDispatch} from "react-redux";


export const EditOrderStatusButton = ({selectedIds}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isExecuting, setExecuting] = useState(false);
    const [status, setStatus] = useState();

    const handleAction = e => {
        e.stopPropagation();
        if (!selectedIds || selectedIds.length === 0) {
            notify('app.message.nothing_selected', 'warning');
            return;
        }
        if (!status) return
        setExecuting(true);

        dataProvider
            .updateOrdersStatus({orderIds: selectedIds, status: status})
            .then(() => {
                notify('app.message.updated_order_status', 'info');
                refresh();
                handleDialogClose();
                dispatch(setListSelectedIds('orders', []));
            })
            .catch(() => {
                notify('app.message.updated_order_status_fail', 'warning')
            })
            .finally(() => {
                setExecuting(false);
                setStatus(undefined);
            });
    };

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Button onClick={handleClick} label='app.message.update_order_status'>
            <StatusIcon/>
        </Button>
        <Confirm
            isOpen={isOpen}
            loading={isExecuting}
            title='app.message.update_order_status_title'
            content={
                <SimpleForm toolbar={false}>
                    <SelectInput
                        source='status'
                        label='resources.orders.fields.status'
                        choices={ORDER_STATUS}
                        onChange={e => setStatus(e?.target?.value)}
                    />
                </SimpleForm>
            }
            onConfirm={handleAction}
            onClose={handleDialogClose}
        />
    </Fragment>
};
