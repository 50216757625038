import React from 'react';
import {activityString} from "../resources/activity/utils";
import classnames from "classnames";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import lodash from "lodash";
import {Labeled} from "react-admin";
import {Fragment} from "react";

export const ActivitiesField = ({record, source, label, ...rest}) => {
    const activities = source ? lodash.get(record, source) : record;
    return <Labeled label={label || "resources.contracts.fields.duties.activities.title"}>
        <Fragment>
            {activities.map(a => <ChipField value={activityString(a)} {...rest}/>)}
        </Fragment>
    </Labeled>
};

const useStyles = makeStyles(
    {
        chip: {margin: 4, cursor: 'inherit'},
    },
    {name: 'RaChipField'}
);


export const ChipField = props => {
    const classes = useStyles(props);
    const {className, value} = props;

    return <Chip
        className={classnames(classes.chip, className)}
        label={value}
    />
};