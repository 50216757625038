export default {
    app: {
        title: "Ferdo Information system",
        email_address: "Email Address",
        login: {
            title: "Welcome to Information system of Ferdo company",
            form_title: "Login",
            forgotten_password: "Forgotten password?",
            forgotten_password_dlg_title: "Forgotten password",
            forgotten_password_dlg_msg: "Please, enter your email and hit the reset button, you will receive an email with reset link soon.",
            new_password: "New password",
            password_reset_sent_msg: "Email with password reset link was sent to your address.",
            support: "Contact/Support",
            errors: {
                general: "Sign in error",
                inactive: "User inactive, signed out",
                user_not_found: "User not found",
                invalid_email: "Provided email is invalid",
                invalid_password: "Invalid password",
                network_error: "Auth server not working",
                invalid_token: "Invalid token, new sign in required",
            }
        },
        message: {
            open: "Open",
            close: "Close",
            reset: "Reset",
            send: "Send",
            save: "Save",
            cancel: "Cancel",
            generate: "Generate",
            created: "Created",
            created_by: "Created %{date} by %{user}",
            no_location: "No location",
            route_gpx_placeholder: "Drop a GPX file or click to select it. For KML to GPX conversion visit: ",
            possible_content_tags: "Possible content tags: <br> - new paragraph, <b>TEXT</b> - bold text",
            no_stay_time: "No stay time defined",
            no_operational: "No operational hours defined",
            no_linking: "No linking",
            no_image: "No image",
            no_media: "No media files",
            no_contacts: "No contacts",
            no_items: "No items",
            no_limitation: "No limitation",
        },
        users: {
            title: "Users management",
            admin: "Admin",
            app: "Application",
            user_count: "Number of users",
            provider: "Provider",
            create_user: "Create new user",
            edit_user: "Edit user",
            error_loading: "Error loding users",
            user_created: "User successfully created",
            user_create_error: "User create failed",
            user_updated: "User successfully updated",
            user_update_error: "User edit failed",
            user_delete_dialog_title: "Delete user",
            user_delete_dialog_content: "Are you sure you want to delete user: ",
            user_deleted: "User successfully deleted",
            user_delete_error: "User delete failed"
        },
        roles: {
            user: "User",
            admin: "Admin",
            super_admin: "Super Admin",
        },
    },
    ra: {
        auth: {
            username: "Email",
        },
        page: {
            invite: "",
        },
        notification: {
            http_error: 'Request was not accepted',
        }
    },
    /**** Resources ****/
    resources: {
        address: {
            title: "Address",
            zip: "Zip",
            city: "City",
            state: "State",
            street: "Street",
            number: "Number",
        },
        users: {
            name: "User |||| Users",
            fields: {
                email: "Email Address",
                firstName: "First name",
                lastName: "Last name",
                role: "User role",
                active: "User active",
            }
        },
        partners: {
            name: "Partner |||| Partners",
            fields: {
                name: "Name",
                shortName: "Short name",
                representative: "Representative",
                ico: "TAX ID",
                dic: "VAT",
                bankName: "Bank",
                bankAccount: "Bank account",
                contact: "Contact",
                phoneNumber: "Phone number",
                email: "Email",
                note: "Note",
                contractor: "Contractor",
            }
        },
        activities: {
            name: "Activity |||| Activities",
            fields: {
                name: "Name",
                shortName: "Short name",
            }
        },
        validation: {
            required: "This field is required",
            length: "This field has to be %{size} characters long",
            number: "This field has to be number",
            email: "Provide valid email address",
            password: "Password must be at least 8 characters long",
            password_repeat: "Passwords must be equal and longer than 8 character",
            title_required: "Title is required",
            media_file_required: "File must be provided when creatign Media item",
            invalid_email: "Invalid email address",
            invalid_phone: "Invalid phone number",
            invalid_url: "Invalid url address",
            start_after_end: "Start can not be defined after end"
        },
        fields: {
            business_title: "Business info",
            general_title: "General info",
            bank_info_title: "Bank info",
        },
    }
};
