import React, {Fragment, useState} from 'react'
import EmployeeIcon from '@mui/icons-material/AssignmentInd';
import {
    Button,
    useNotify,
    useDataProvider,
    Confirm,
    useRefresh,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    useTranslate, setListSelectedIds,
} from 'react-admin';
import {fullNameText} from "./FullNameField";
import {useDispatch} from "react-redux";


export const EditEmployeeButton = ({selectedIds}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const [isOpen, setOpen] = useState(false);
    const [isExecuting, setExecuting] = useState(false);
    const [employeeId, setEmployeeId] = useState();


    const handleAction = e => {
        e.stopPropagation();
        if (!selectedIds || selectedIds.length === 0) {
            notify('app.message.nothing_selected', 'warning');
            return;
        }
        setExecuting(true);

        dataProvider
            .assignEmployeeToOrders({orderIds: selectedIds, employeeId: employeeId})
            .then(() => {
                notify('app.message.assigned_employee', 'info');
                refresh();
                handleDialogClose();
                dispatch(setListSelectedIds('orders', []));
            })
            .catch(() => {
                notify('app.message.assigned_employee_fail', 'warning')
            })
            .finally(() => {
                setEmployeeId(undefined);
                setExecuting(false)
            });
    };

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Button onClick={handleClick} label='app.message.assignee'>
            <EmployeeIcon/>
        </Button>
        <Confirm
            isOpen={isOpen}
            loading={isExecuting}
            title='app.message.assignee_title'
            content={
                <SimpleForm toolbar={false}>
                    <ReferenceInput
                        source='assignee'
                        reference='employees'
                        sort={{field: 'lastName', order: 'ASC'}}
                        filter={{active: true}}
                        filterToQuery={searchText => ({fullName: searchText})}
                        label='resources.orders.fields.assignee'
                        onChange={setEmployeeId}
                        allowEmpty allowNull={null}
                    >
                        <AutocompleteInput
                            optionText={fullNameText(translate('resources.orders.fields.noAssignee'))}
                        />
                    </ReferenceInput>
                </SimpleForm>
            }
            onConfirm={handleAction}
            onClose={handleDialogClose}
        />
    </Fragment>
};
