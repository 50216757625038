import React, {useLayoutEffect} from "react";
import {
    useDataProvider,
    useNotify,
    useRedirect,
} from "react-admin";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import skLocale from "@fullcalendar/core/locales/sk";

import {ROLE_USER} from "../../user/constants";

import qs from "qs";
import {stringify} from 'query-string';
import {useSelector} from "react-redux";
import {getEventDrop, getEvents} from "./data";
import {getEventContent} from "./event";
import {Legend} from "./legend";
import {CalendarFilter} from "./filter";
import {Draggable} from "@fullcalendar/interaction";


const OrderCalendar = ({permissions, basePath, ...props}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    useLayoutEffect(() => {
        let draggableEl = document.getElementById("fullcalendar");

        new Draggable(draggableEl, {
            itemSelector: ".draggable-event",
            eventData: {
                create: false,
            }
        });
    }, [basePath])


    const params = qs.parse(props?.location?.search, {ignoreQueryPrefix: true});
    const storedStateFilter = useSelector(
        (state) => state?.admin?.resources['orders']?.list?.params?.filter
    );
    const filter = params.filter ? JSON.parse(params?.filter) : storedStateFilter ? storedStateFilter : {}
    const calendarFilter = filter?.calendarFilter ? JSON.parse(filter?.calendarFilter) : {}

    const setCalendarFilter = newFilter => {
        redirect(`/orders?${stringify({
            ...params,
            filter: JSON.stringify({
                ...filter,
                calendarFilter: JSON.stringify(newFilter),
            })
        })}`)
    };

    const dropEvent = dropInfo => {
        const elements = document.elementsFromPoint(dropInfo?.jsEvent?.clientX, dropInfo?.jsEvent?.clientY)
        const droppableContainer = elements.find(el => el.className.includes('droppable-container'));
        if (!droppableContainer) return;
        const droppableElement = droppableContainer.querySelector('.droppable-element');
        if (!droppableElement) return;

        const realizationDate = droppableElement.getAttribute("realizationDate");
        const assigneeId = Number(droppableElement.getAttribute("assigneeId"));
        const orderId = Number(dropInfo?.draggedEl.getAttribute("orderId"));

        const successCallback = () => {
            droppableElement.appendChild(dropInfo?.draggedEl)
            const sorted = [...droppableElement.childNodes]
                .sort((a, b) => a.innerText.localeCompare(b.innerText));
            droppableElement.innerHTML = '';
            droppableElement.append(...sorted);
        }
        getEventDrop(dataProvider, notify, successCallback)(orderId, realizationDate, assigneeId);
    };

    const getEventsFunc = (fetchInfo, successCallback, failureCallback) => (
        getEvents(dataProvider, calendarFilter)(fetchInfo, successCallback, failureCallback)
    );

    return <div id='fullcalendar'>
        {permissions !== ROLE_USER &&
        <CalendarFilter initialValues={calendarFilter} handleOnChange={(source, value) => {
            const newFilter = {...calendarFilter, [source]: value};
            if (!value) {
                delete newFilter[source];
            }
            setCalendarFilter(newFilter)
        }}/>}
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridWeek"
            locale={skLocale}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridWeek,dayGridMonth'
            }}
            height='auto'
            drop={dropEvent}
            events={getEventsFunc}
            eventContent={getEventContent(permissions, props?.location?.search)}
            eventBorderColor={'rgb(255, 255, 255)'}
        />
        <Legend/>
    </div>
};


export default OrderCalendar;
