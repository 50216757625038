export const sum = (a, b) => a + b;

const getMinutes = activity => {
    return activity && activity.minutes ? activity.minutes : 0;
};

const totalMinutes = activities => {
    if (!activities) return 0;
    return activities.map(getMinutes).reduce(sum, 0);
};

export const convertToHours = minutes => {
    return Math.round(minutes / 60 * 100) / 100;
};

export const totalHours = activities => {
    return convertToHours(totalMinutes(activities));
};

export const prettyTimeSumString = activities => {
    const tMinutes = totalMinutes(activities);
    return prettyTimeString(tMinutes);
};

export const prettyTimeString = tMinutes => {
    if (!tMinutes || tMinutes <= 0) return '0m';

    const hours = Math.floor(tMinutes / 60);
    const minutes = tMinutes - hours * 60;
    const h = hours > 0 ? `${hours}h` : '';
    const m = minutes > 0 ? `${minutes}m` : '';
    const space = h && m ? ' ' : '';

    return `${h}${space}${m}`;
};

export const activityString = record => `${record.shortName} - ${prettyTimeString(record.minutes)}`;
