import React from 'react';
import {Edit, useNotify} from 'react-admin';
import ContractForm from "./form";


export const ContractEdit = ({permissions, ...props}) => {
    const notify = useNotify();
    return <Edit
        {...props}
        transform={data => {
            // console.log(data);
            return data;
        }}
        mutationMode="pessimistic"
        onSuccess={() => {
            notify('ra.notification.updated', 'info', {smart_count: 1});
            return props?.history?.goBack();
        }}
    >
        <ContractForm permissions={permissions}/>
    </Edit>
};

export default ContractEdit;