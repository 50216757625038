import {
    Button,
    Confirm,
    useDataProvider,
    useLocale,
    useNotify, useRedirect,
    useTranslate
} from "react-admin";
import React, {Fragment, useState} from "react";
import {EXECUTED, ORDER_STATUS, PLANNED, PLANNED_ONE_TIME} from "../constants";
import {ROLE_USER} from "../../user/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {getAddressTitle, Tooltip} from "../../../components";
import {prettyTimeString} from "../../activity/utils";
import {periodicityName} from "../../contract/constants";
import {partnerAddressAdditionalInfo} from "../../partner/utils";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    event: {
        borderRadius: '3px',
        whiteSpace: 'initial',
        paddingLeft: '3px',
        margin: '1px',
    },
});


export const OrderContent = ({permissions, location, order, calendarApi}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const redirect = useRedirect();
    const classes = useStyles();

    const [openTooltip, setOpenTooltip] = useState(false);
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleExecuteOrder = () => {
        setIsExecuting(true);
        dataProvider
            .updateOrdersStatus({orderIds: [order.id], status: EXECUTED})
            .then(() => {
                notify('app.message.updated_order_status', 'info');
                setIsOpenConfirmation(false);
                setOpenTooltip(false);
                calendarApi.refetchEvents();
            })
            .catch(() => {
                notify('app.message.updated_order_status_fail', 'warning')
            })
            .finally(() => {
                setIsExecuting(false);
            });
    }

    const activitiesText = (activities, permissions) => {
        if (!activities) return '';
        const formattedActivities = permissions === ROLE_USER
            ? activities.map(a => `- ${a.name}`)
            : activities.map(a => `- ${a.shortName}`);
        return `<br>${formattedActivities.join('<br>')}`
    };

    const duty = order?.duty || order?.oneTimeDuty
    const isOneTimeOrder = !!order?.oneTimeDuty;
    const dutyAddress = order?.duty ? duty?.address?.address : duty?.address

    const executeButton = <Fragment>
        <Button
            onClick={() => setIsOpenConfirmation(true)}
            label='app.message.executed_order_button'
            style={{display: 'flex', marginLeft: 'auto', marginRight: '0px'}}
        >
            <CheckCircleIcon/>
        </Button>
        <Confirm
            isOpen={isOpenConfirmation}
            loading={isExecuting}
            title='app.message.executed_order_confirm_title'
            content='app.message.executed_order_confirm_content'
            onConfirm={handleExecuteOrder}
            onClose={() => setIsOpenConfirmation(false)}
        />
    </Fragment>

    const eventDetails = isOneTimeOrder ?
        <div>
            {permissions !== ROLE_USER &&
            <div>{translate('resources.orders.fields.oneTimeDuty.price')}: {duty?.price} €</div>}
            <div>{translate('resources.contracts.fields.duties.name')}: {duty?.name}</div>
            <div>{translate('resources.orders.fields.code')}: {order.code}</div>
            <div>
                {translate('resources.orders.fields.oneTimeDuty.time')}: {new Date(order?.realizationDate).toLocaleTimeString(locale, {
                hour: 'numeric',
                minute: 'numeric'
            })}
            </div>
            <div>{translate('resources.orders.fields.duration')}: {prettyTimeString(order?.overrideMinutes)}</div>
            <div dangerouslySetInnerHTML={
                {__html: `${translate('resources.orders.fields.oneTimeDuty.note')}: ${duty?.note || ''}`}
            }/>
            {permissions === ROLE_USER && order.status === PLANNED && executeButton}
        </div> :
        <div>
            <div>{translate('resources.contracts.fields.duties.periodicity')}: {periodicityName(translate, order.duty)}</div>
            <div>{translate('resources.contracts.fields.duties.name')}: {duty?.name}</div>
            <div>{translate('resources.orders.fields.code')}: {order.code}</div>
            <div>{partnerAddressAdditionalInfo(translate, duty?.address)}</div>
            <div dangerouslySetInnerHTML={
                {__html: `${translate('resources.contracts.fields.duties.activities.title')}: ${activitiesText(duty?.activities, permissions)}`}
            }/>
            {permissions === ROLE_USER && order.status === PLANNED && executeButton}
        </div>;

    const OrderTitle = ({onClick}) => {
        const orderStatus = ORDER_STATUS.find(o => o.id === order?.status);
        const color = order?.status === PLANNED && order?.oneTimeDuty ? PLANNED_ONE_TIME.color : orderStatus?.color;

        return <div
            className={classes.event}
            style={{
                backgroundColor: color,
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            {getAddressTitle(dutyAddress)}
        </div>
    }

    const clickEvent = <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
        <div>
            <Tooltip
                PopperProps={{sx: {zIndex: 1000}}}
                open={openTooltip}
                title={eventDetails}
            >
                <OrderTitle onClick={() => setOpenTooltip(true)}/>
            </Tooltip>
        </div>
    </ClickAwayListener>;

    const hoverEvent = <Tooltip title={eventDetails} disableInteractive>
        <OrderTitle onClick={() => redirect(`orders/${order?.id}${location}`)}/>
    </Tooltip>;

    return <div
        className='draggable-event'
        orderId={order?.id}
    >
        {permissions === ROLE_USER ? clickEvent : hoverEvent}
    </div>
};
