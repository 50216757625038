import React, {Children, cloneElement, useState} from "react";
import {ExpandMore} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {Accordion, AccordionDetails} from '@material-ui/core';
import {withTranslate} from 'react-admin';


const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
})(MuiAccordionSummary);


export const CustomAccordion = withTranslate(({title, children, translate, label, className, summaryProps, detailsClassName, ...props}) => {

    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return <Accordion
        TransitionProps={{unmountOnExit: true}}
        onChange={handleAccordionChange}
        className={className}
        {...props}
    >
        <AccordionSummary expandIcon={<ExpandMore/>} {...summaryProps} >
            {title ? title : expanded ? translate('app.message.expand_hide') : translate('app.message.expand_show')}
        </AccordionSummary>
        <AccordionDetails className={detailsClassName}>
            {Children.map(children, ch => cloneElement(ch, props))}
        </AccordionDetails>
    </Accordion>
});
