import React from 'react';
import {Create, SimpleForm, SelectInput, TextInput} from 'react-admin';
import {availableRoles} from './constants';
import {required, requiredEmail} from '../../data/validation'

export const UserCreate = ({permissions, ...props}) => {
    return <Create {...props}>
        <SimpleForm redirect='/users'>
            <TextInput source="email" type="email" required validate={requiredEmail}/>
            <TextInput source="firstName"/>
            <TextInput source="lastName"/>
            <SelectInput source="role" choices={availableRoles(permissions)} required validate={required}/>
        </SimpleForm>
    </Create>
};

export default UserCreate;