import React from "react";
import {useTranslate} from "react-admin";
import {CustomAccordion, fullNameText} from "../../../components";
import {OrderContent} from "./orderContent";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    title: {
        margin: '-0.5px',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1px',
        color: 'rgb(255,255,255)'
    },
});

export const getEventContent = (permissions, location) => {
    return eventInfo => {
        return <Event
            {...eventInfo?.event?.extendedProps}
            calendarApi={eventInfo.view.getCurrentData().calendarApi}
            permissions={permissions}
            location={location}
        />
    }
};


const Event = ({orders, location, permissions, calendarApi, assigneeId, realizationDate}) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (!orders || orders.length === 0) return <div/>;

    return <CustomAccordion
        className='droppable-container'
        title={fullNameText(translate('resources.orders.fields.noAssignee'))(orders[0]?.assignee)}
        detailsClassName={classes.details}
        summaryProps={{style: {minHeight: '30px', maxHeight: '30px'}}}
        square
        defaultExpanded
        TransitionProps={{unmountOnExit: false}}
    >
        <div
            className='droppable-element'
            realizationDate={realizationDate}
            assigneeId={assigneeId}
        >
            {orders.map(o => {
                return <OrderContent
                    permissions={permissions}
                    location={location}
                    order={o}
                    calendarApi={calendarApi}
                />
            })}
        </div>
    </CustomAccordion>
};
