export const DUTY_PERIODICITY = [
    {id: "ONCE", name: "app.duty_periodicity.once", code: "J"},
    {id: "DAY", name: "app.duty_periodicity.day", code: "D"},
    {id: "WEEK", name: "app.duty_periodicity.week", code: "T"},
    {id: "TWO_WEEKS", name: "app.duty_periodicity.two_weeks", code: "TT"},
    {id: "MONTH", name: "app.duty_periodicity.month", code: "M"},
    {id: "QUARTER", name: "app.duty_periodicity.quarter", code: "K"},
    {id: "HALF_A_YEAR", name: "app.duty_periodicity.half_a_year", code: "P"},
    {id: "YEAR", name: "app.duty_periodicity.year", code: "R"},
]

export const orderCodeSuggestion = (contractNumber, subcontractNumber, periodicity, translate) => {
    const p = DUTY_PERIODICITY.find(p => p.id === periodicity);
    if (!p) return translate('app.message.unknown_periodicity');
    const z = contractNumber === null || contractNumber === undefined ? translate('app.message.unknown_contract_number') : contractNumber;
    const d = subcontractNumber === null || subcontractNumber === undefined ? translate('app.message.unknown_subcontract_number') : subcontractNumber;
    const o = translate('app.message.order_number')
    return translate('app.message.order_code', {code: `${z}_${d}_${p.code}_${o}`});
};

export const periodicityName = (translate, value) => {
    if (!value) return '';

    const periodicity = DUTY_PERIODICITY.find(p => value.periodicity ? p.id === value.periodicity: p.id === value);
    if (!periodicity) return '';

    return translate(periodicity.name)
}