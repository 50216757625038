import React from 'react';
import {
    Login as ReactAdminLogin,
    SimpleForm,
    TextInput,
    PasswordInput,
    SaveButton,
    useRedirect,
} from 'react-admin';
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import authProvider from "./authProvider";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        zIndex: 2,
        marginTop: '11em',
    },
});

const InvitationPage = ({invitationToken, email}) => {
    const classes = useStyles();
    const redirect = useRedirect();

    return <ReactAdminLogin
        classes={{card: classes.root}}
        style={{backgroundImage: "radial-gradient(circle at 50% 14em, rgb(172 169 169) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%)"}}
        children={
            <Card>
                <CardContent>
                    <SimpleForm
                        save={(values) => {
                            authProvider.acceptInvite({
                                invitationToken: invitationToken,
                                email: values["email"],
                                password: values["password"],
                            }).then(() => redirect("/"));
                        }}
                        toolbar={<SaveButton label='ra.action.save'/>}
                    >
                        <TextInput source="email" type="email" initialValue={email} disabled label='app.email'/>
                        <PasswordInput source="password" label='app.login.new_password'/>
                    </SimpleForm>
                </CardContent>
            </Card>
        }/>;
};

export default InvitationPage;
