import React from 'react';
import {
    Create,
    SelectInput,
    DateTimeInput,
    AutocompleteInput,
    ReferenceInput,
    useTranslate,
} from 'react-admin';
import {fullNameText, OneTimeDutyInput} from "../../components";
import {NO_ORDER_BONUS, ORDER_BONUSES, ORDER_STATUS, PLANNED} from "./constants";
import moment from "moment";
import {CompactForm, RaBox} from "ra-compact-ui";
import {required} from "../../data/validation";

export const OrderCreate = ({permissions, ...props}) => {
    const translate = useTranslate();

    return <Create {...props}>
        <CompactForm layoutComponents={[RaBox]} redirect="/orders">
            <SelectInput source="status" choices={ORDER_STATUS} defaultValue={PLANNED}/>
            <DateTimeInput
                source="realizationDate"
                defaultValue={moment().startOf('day').toISOString()}
                parse={value => value}
                required
                validate={required}
            />
            <SelectInput source="bonus" choices={ORDER_BONUSES} defaultValue={NO_ORDER_BONUS}/>
            <ReferenceInput
                source="assignee"
                reference="employees"
                sort={{field: 'lastName', order: 'ASC'}}
                filter={{active: true}}
                filterToQuery={searchText => ({fullName: searchText})}
                label='resources.orders.fields.assignee'
                parse={value => value && ({id: value})}
                format={value => value ? value.id : value}
                allowEmpty allowNull={null}
            >
                <AutocompleteInput optionText={fullNameText(translate('resources.orders.fields.noAssignee'))}/>
            </ReferenceInput>

            <OneTimeDutyInput/>

        </CompactForm>
    </Create>
};

export default OrderCreate;