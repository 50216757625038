import React from 'react';
import {Create} from 'react-admin';
import ContractForm from "./form";

export const ContractCreate = ({permissions, ...props}) => {
    return <Create
        {...props}
        transform={data => {
            // console.log(data);
            return data;
        }}
    >
        <ContractForm permissions={permissions}/>
    </Create>
};

export default ContractCreate;