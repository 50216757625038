import React from 'react';
import {Edit} from 'react-admin';
import PartnerForm from "./form";


export const PartnerEdit = ({permissions, ...props}) => {
    return <Edit {...props}
                 transform={data => {
                     // console.log(data);
                     return data;
                 }}
                 mutationMode="pessimistic"
    >
        <PartnerForm permissions={permissions}/>
    </Edit>
};

export default PartnerEdit;