import React, {useMemo} from 'react';
import {
    List,
    Datagrid,
    TextField,
    useTranslate,
    Pagination,
    DateField,
    useLocale,
    SelectField,
    Button,
    useListController,
    TextInput,
    SelectInput,
    NumberInput,
    DateInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    linkToRecord,
} from 'react-admin';
import {
    ActivitiesField,
    AddressField,
    ListActions,
    ListBulkActions, safeOptionText, useExpandOnlyOne
} from "../../components";
import {DutiesExporter} from "../../data/export";
import {DUTY_PERIODICITY} from "../contract/constants";
import OrderIcon from '@mui/icons-material/PlaylistAddCheck';
import EditIcon from '@mui/icons-material/Create';
import {Link} from 'react-router-dom';
import {activityString} from "../activity/utils";
import {makeStyles} from "@material-ui/core";
import {ACTIVITY_TYPE} from "../activity/constants";


const useStyles = makeStyles({
    contract: {
        textAlign: 'right',
        paddingRight: 0,
    },
});

const ContractButton = ({record}) => (
    <Button
        style={{marginRight: 0}}
        component={Link}
        label='ra.action.edit'
        to={useMemo(() => ({
                pathname: record ? linkToRecord('contracts', record.contractId) : '',
                state: {_scrollToTop: true},
            }), [record]
        )}
    >
        <EditIcon/>
    </Button>
);

const OrderButton = ({record}) => (
    <Button
        component={Link}
        label='app.message.filter_orders_button'
        to={{
            pathname: '/orders',
            search: `displayedFilters=%7B%22duty.id%22%3Atrue%7D&filter=%7B%22duty%22%3A%7B%22id%22%3A%22${record?.id}%22%7D%7D&order=ASC&page=1&perPage=50&sort=realizationDate`,
        }}
    >
        <OrderIcon/>
    </Button>
);

const dutyFilters = disabled => [
    // <DateInput
    //     source="minOrderDate"
    //     label="resources.duties.filters.minOrderDate"
    //     disabled={disabled}
    // />,
    <DateInput
        alwaysOn
        source="maxOrderDate"
        label="resources.duties.filters.maxOrderDate"
        disabled={disabled}
    />,
    <TextInput
        alwaysOn
        source="contractNumber"
        disabled={disabled}
    />,
    <TextInput
        alwaysOn
        source="dutyAddress"
        label="resources.duties.filters.dutyAddress"
        disabled={disabled}
    />,
    <SelectInput
        source="periodicity"
        choices={DUTY_PERIODICITY}
        disabled={disabled}
    />,
    <NumberInput
        source="ordersCount"
        disabled={disabled}
    />,
    <ReferenceArrayInput
        fullWidth
        source="activityIds"
        reference="activities"
        label="resources.duties.filters.activityIds"
        sort={{field: 'shortName', order: 'ASC'}}
        filterToQuery={searchText => ({shortName: searchText})}
        perPage={100}
        allowEmpty={false}
    >
        <AutocompleteArrayInput optionText={safeOptionText(activityString)}/>
    </ReferenceArrayInput>,
    <SelectInput
        source="activityType"
        label="resources.duties.filters.activityType"
        choices={ACTIVITY_TYPE}
    />,
];

const DutyList = ({permissions, ...props}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();
    const {selectedIds} = useListController(props);
    useExpandOnlyOne(props.resource, props.location);
    const isSomethingSelected = selectedIds && selectedIds.length > 0;

    return <List
        {...props}
        actions={<ListActions filter hasCreate={false}/>}
        filters={dutyFilters(isSomethingSelected)}
        bulkActionButtons={<ListBulkActions disableRemove/>}
        exporter={DutiesExporter(translate)}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]}/>}
        perPage={50}
    >
        <Datagrid
            rowClick="expand"
            expand={<ActivitiesField source="activities"/>}
        >
            <TextField source="id"/>
            <TextField source="contractNumber"/>
            {/*<TextField source="contractSubject"/>*/}
            <AddressField source="address.address" sortBy={['address.address.street,address.address.number']}/>
            <TextField source="name"/>
            <DateField source="startDate" locales={locale}
                       options={{year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long'}}/>
            <SelectField source="periodicity" choices={DUTY_PERIODICITY}/>
            <DateField source="minOrderDate" locales={locale}
                       options={{year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long'}}/>
            <DateField source="maxOrderDate" locales={locale}
                       options={{year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long'}}/>
            <TextField source="ordersCount"/>
            <ContractButton cellClassName={classes.contract}/>
            <OrderButton/>
        </Datagrid>
    </List>
};

export default DutyList;