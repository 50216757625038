import React from "react";
import {
    ArrayInput,
    AutocompleteInput,
    ReferenceInput,
    ReferenceArrayInput,
    FormDataConsumer,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    DateInput,
    FileInput,
    useTranslate,
    BooleanInput,
} from "react-admin";

import {CompactForm, RaBox} from "ra-compact-ui";
import RichTextInput from "ra-input-rich-text";

import {
    Toolbar,
    CreateDialog,
    EditDialog,
    AddressField,
    AutocompleteArrayInput,
    safeOptionText,
    LazyFileField,
} from "../../components";
import PartnerForm from "../partner/form";
import ActivityForm from "../activity/form";
import {DUTY_PERIODICITY, orderCodeSuggestion} from "./constants";
import {
    required,
    requiredPositiveNumber,
    requiredWithoutSpace,
    validateContractForm
} from "../../data/validation";
import {activityString, prettyTimeSumString} from "../activity/utils";
import {Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {dateToUtcString} from "../../components/utils";


const useStyles = makeStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        paddingLeft: '10px',
        paddingTop: "8px",
        minWidth: '200px',
        maxWidth: '200px',
    },
});


const ContractForm = ({permissions, basePath, resource, save}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <CompactForm
            validate={validateContractForm}
            resource={resource}
            layoutComponents={[RaBox]}
            save={save}
            toolbar={<Toolbar permissions={permissions}/>}
        >
            <RaBox display="flex" justifyContent="space-between">
                <RaBox flex='2'>
                    <ReferenceInput
                        source="customer.id"
                        reference="partners"
                        sort={{field: 'shortName', order: 'ASC'}}
                        filter={{contractor: false}}
                        isRequired validate={required}
                        label='resources.contracts.fields.customer.name'
                        filterToQuery={searchText => ({shortName: searchText})}
                    >
                        <AutocompleteInput
                            optionText="shortName"
                            create={<CreateDialog
                                resource='partners'
                                form={<PartnerForm disableContractor/>}
                                title='app.message.create_partner'
                            />}
                        />
                    </ReferenceInput>
                    <ReferenceInput
                        source="contractor.id"
                        reference="partners"
                        sort={{field: 'shortName', order: 'ASC'}}
                        filter={{contractor: true}}
                        isRequired validate={required}
                        label='resources.contracts.fields.contractor.name'
                        perPage={100}
                    >
                        <SelectInput optionText="shortName"/>
                    </ReferenceInput>
                    <DateInput source='validFrom' required validate={required}/>

                </RaBox>
                <RaBox flex='2'>
                    <TextInput source='subject'/>
                    <TextInput source='price'/>
                    <DateInput source='validUntil'/>
                </RaBox>
                <RaBox flex='2'>
                    <TextInput source='contractNumber' required validate={requiredWithoutSpace}/>
                    <TextInput source='subcontractNumber' initialValue={0} isRequired
                               validate={requiredPositiveNumber}/>
                    <TextInput source='terminationReason'/>
                </RaBox>
                <RaBox flex='1'>
                    <BooleanInput
                        source='winterMaintenance'
                        initialValue={false}
                    />
                    <FileInput
                        source="attachments"
                        multiple
                        maxSize={5000000 /*5 MB*/}
                        parse={files => files && files.map(f => f instanceof File ? {
                            id: null,
                            title: f.name,
                            rawFile: f
                        } : f)}
                    >
                        <LazyFileField/>
                    </FileInput>
                </RaBox>
            </RaBox>
            <RichTextInput source='note'/>
            <ArrayInput source='duties' label='resources.contracts.fields.duties.title'>
                <SimpleFormIterator disableReordering>
                    <FormDataConsumer>
                        {({getSource, formData, scopedFormData}) => {
                            const activitiesSumTime = choices => {
                                const activities = scopedFormData && scopedFormData.activities && scopedFormData.activities.map(a => choices.find(c => c.id === a.id));
                                return translate(
                                    'resources.contracts.fields.duties.activitiesDuration',
                                    {sum: prettyTimeSumString(activities)}
                                );
                            }
                            return formData.customer && formData.customer.id ? <RaBox>
                                    <RaBox display='inline-flex' justifyContent='space-between'>
                                        <ReferenceInput
                                            source={getSource("address.id")}
                                            reference='partners/addresses'
                                            filter={{id: formData.customer.id}}
                                            label='resources.contracts.fields.duties.address.title'
                                            perPage={100}
                                            isRequired
                                        >
                                            <SelectInput
                                                optionText={safeOptionText(record => <AddressField source="address"
                                                                                                   record={record}/>)}
                                                create={<EditDialog
                                                    basePath={basePath}
                                                    resource='partners'
                                                    id={formData.customer.id}
                                                    form={<PartnerForm permissions={permissions} disableRemove/>}
                                                    title='app.message.update_partner'
                                                    setCreated={false}
                                                />}
                                            />
                                        </ReferenceInput>
                                        <TextInput
                                            style={{marginLeft: '10px'}}
                                            source={getSource('name')}
                                            record={scopedFormData}
                                            label='resources.contracts.fields.duties.name'
                                        />
                                        <DateInput
                                            style={{marginLeft: '10px'}}
                                            source={getSource('startDate')}
                                            record={scopedFormData}
                                            label='resources.contracts.fields.duties.startDate'
                                            parse={dateToUtcString}
                                            isRequired
                                        />
                                        <SelectInput
                                            style={{marginLeft: '10px'}}
                                            source={getSource('periodicity')}
                                            record={scopedFormData}
                                            choices={DUTY_PERIODICITY}
                                            label='resources.contracts.fields.duties.periodicity'
                                            isRequired
                                        />
                                        {
                                            scopedFormData && scopedFormData.periodicity ?
                                                <div className={classes.root}>
                                                    <Typography component='div'>
                                                        {orderCodeSuggestion(formData.contractNumber, formData.subcontractNumber, scopedFormData.periodicity, translate)}
                                                    </Typography></div>
                                                : <div/>
                                        }
                                    </RaBox>
                                    <RaBox>
                                        <ReferenceArrayInput
                                            fullWidth
                                            source={getSource("activities")}
                                            reference="activities"
                                            sort={{field: 'shortName', order: 'ASC'}}
                                            label='resources.contracts.fields.duties.activities.title'
                                            parse={value => (value && value.map(v => ({id: v})))}
                                            format={value => (value && value.map(v => v && v.id))}
                                            filterToQuery={searchText => ({shortName: searchText})}
                                            perPage={100}
                                        >
                                            <AutocompleteArrayInput
                                                optionText={safeOptionText(activityString)}
                                                create={<CreateDialog
                                                    resource='activities'
                                                    form={<ActivityForm/>}
                                                    title='app.message.create_activity'
                                                />}
                                                tooltip={activitiesSumTime}
                                            />
                                        </ReferenceArrayInput>
                                    </RaBox>
                                </RaBox>
                                : translate('resources.validation.missing_customer')
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>

            </ArrayInput>
        </CompactForm>
    )
};

export default ContractForm;