import {createTheme} from '@material-ui/core/styles'
import {red} from '@material-ui/core/colors';

const defaultTheme = createTheme({
    palette: {
        primary: {main: '#4a126b'},
        secondary: {main: '#ce1c4c'},
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    }
});

export default defaultTheme;