import React from 'react';
import {Create} from 'react-admin';
import PartnerForm from "./form";


export const PartnerCreate = (props) => {
    return <Create {...props}>
        <PartnerForm {...props}/>
    </Create>
};

export default PartnerCreate;