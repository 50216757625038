const convertFileToBase64 = file => new Promise((resolve, reject) => {
    if (file === null || file === undefined) {
        resolve(null);
        return;
    }
    const reader = new FileReader();

    reader.onload = () => resolve({rawFile: reader.result});
    reader.onerror = reject;

    reader.readAsDataURL(file);
});

const convertBase64ToFile = (base64Content, filename) => new Promise((resolve, reject) => {
    try {
        const arr = base64Content.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const file = new File([u8arr], filename, {type: mime});
        resolve({rawFile: file, src: URL.createObjectURL(file), title: filename});
    } catch (e) {
        reject()
    }
});

const recursivelyConvertWithPromises = (obj, conditionFunction, convertFunction) => {
    return Promise
        .resolve()
        .then(() => obj)
        .then(function recursive (obj) {

            if (!obj || typeof obj !== 'object') return obj;

            if (conditionFunction(obj))
                return convertFunction(obj);

            const promises = [];
            for (const key in obj) {
                const item = obj[key];
                promises.push(Promise.resolve().then(() => recursive(item)).then(convertedItem => {
                    if (Array.isArray(obj)) {
                        return convertedItem;
                    } else {
                        return {[key]: convertedItem};
                    }
                }));
            }
            return Promise.all(promises).then(results => {
                if (Array.isArray(obj))
                    return results;
                else
                    return results.reduce((p, c) => ({...p, ...c}), {});
            });
        })
};

export const recursivelyFilesToBase64 = obj => recursivelyConvertWithPromises(
    obj,
    o => o.hasOwnProperty('rawFile') && o.rawFile instanceof File,
    o => convertFileToBase64(o.rawFile).then(converted => ({...o, ...converted}))
);

export const recursivelyBase64ToFiles = obj => recursivelyConvertWithPromises(
    obj,
    o => o.hasOwnProperty('rawFile') && o.rawFile && o.hasOwnProperty('title'),
    o => convertBase64ToFile(o.rawFile, o.title).then(converted => ({...o, ...converted}))
);