import React, {Fragment, useState} from 'react'
import BonusIcon from '@mui/icons-material/Euro';
import {
    Button,
    useNotify,
    useDataProvider,
    Confirm,
    useRefresh,
    SimpleForm,
    SelectInput,
    setListSelectedIds,
} from 'react-admin';
import {ORDER_BONUSES} from "../resources/order/constants";
import {useDispatch} from "react-redux";


export const EditOrderBonusButton = ({selectedIds}) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isExecuting, setExecuting] = useState(false);
    const [bonus, setBonus] = useState();

    const handleAction = e => {
        e.stopPropagation();
        if (!selectedIds || selectedIds.length === 0) {
            notify('app.message.nothing_selected', 'warning');
            return;
        }
        if (bonus === null || bonus === undefined) return
        setExecuting(true);

        dataProvider
            .updateOrdersBonus({orderIds: selectedIds, bonus: bonus})
            .then(() => {
                notify('app.message.updated_order_bonus', 'info');
                refresh();
                handleDialogClose();
                dispatch(setListSelectedIds('orders', []));
            })
            .catch(() => {
                notify('app.message.updated_order_bonus_fail', 'warning')
            })
            .finally(() => {
                setExecuting(false);
                setBonus(undefined);
            });
    };

    const handleClick = e => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    return <Fragment>
        <Button onClick={handleClick} label='app.message.update_order_bonus'>
            <BonusIcon/>
        </Button>
        <Confirm
            isOpen={isOpen}
            loading={isExecuting}
            title='app.message.update_order_bonus_title'
            content={
                <SimpleForm toolbar={false}>
                    <SelectInput
                        source='bonus'
                        label='resources.orders.fields.bonus'
                        choices={ORDER_BONUSES}
                        onChange={e => setBonus(e?.target?.value)}
                    />
                </SimpleForm>
            }
            onConfirm={handleAction}
            onClose={handleDialogClose}
        />
    </Fragment>
};
