import React, {useState} from 'react';
import {Login as ReactAdminLogin, useTranslate, useNotify} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import validator from "validator";

import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import authProvider from "./authProvider";

const useStyles = makeStyles({
    main: {},
    root: {
        zIndex: 2,
        marginTop: '11em',
    },
    supportContainer: {
        position: "absolute",
        left: 0, right: 0,
        bottom: 0,
        textAlign: "center"
    },
    supportLink: {
        color: "rgba(0, 0, 0, 0.87)",
        margin: "8px",
        cursor: "pointer",
        textDecoration: "underline"
    }
});

const LoginPage = () => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const [forgottenPasswordEmail, setForgottenPasswordEmail] = useState('');

    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();

    const handleForgottenPasswordClicked = () => {
        setDialogOpened(!dialogOpened);
        if (!dialogOpened) {
            setForgottenPasswordEmail('');
        }
    };

    const submitForgottenPasswordEmail = () => {
        if (forgottenPasswordEmail && validator.isEmail(forgottenPasswordEmail)) {
            authProvider.forgottenPassword({email: forgottenPasswordEmail});
            notify(translate("app.login.password_reset_sent_msg"));
            setDialogOpened(false);
        } else {
            notify(translate("app.login.errors.invalid_email"));
        }
    };

    return (
        <div className={classes.main}>
            <ReactAdminLogin
                classes={{card: classes.root}}
                style={{backgroundImage: "radial-gradient(circle at 50% 14em, rgb(172 169 169) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%)"}}
            />
            <div className={classes.supportContainer}>
                <Button className={classes.supportLink} onClick={handleForgottenPasswordClicked}>
                    {translate("app.login.forgotten_password")}
                </Button>
                <Button className={classes.supportLink} href="mailto:podpora@ferdo.sk">
                    {translate("app.login.support")}
                </Button>
            </div>
            <Dialog
                open={dialogOpened}
                keepMounted
                onClose={handleForgottenPasswordClicked}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("app.login.forgotten_password_dlg_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate("app.login.forgotten_password_dlg_msg")}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label={translate('app.email')}
                        type="email"
                        fullWidth
                        onChange={(e) => setForgottenPasswordEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForgottenPasswordEmail} color="primary">
                        {translate("app.message.reset")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LoginPage;
