import React from 'react';
import {Admin, Resource} from 'react-admin';
import UserIcon from '@material-ui/icons/People';
import PartnerIcon from '@material-ui/icons/Business';
import ActivityIcon from '@mui/icons-material/CleaningServices';
import ContractIcon from '@mui/icons-material/Assignment';
import EmployeeIcon from '@mui/icons-material/AssignmentInd';
import OrderIcon from '@mui/icons-material/PlaylistAddCheck';
import DutyIcon from '@mui/icons-material/CollectionsBookmark';

import Layout from './Layout';

import {UserList, UserEdit, UserCreate} from './resources/user';
import {ContractList, ContractEdit, ContractCreate} from './resources/contract';
import {DutyList} from './resources/duty';
import {PartnerList, PartnerEdit, PartnerCreate} from './resources/partner';
import {ActivityList, ActivityEdit, ActivityCreate} from './resources/activity';
import {EmployeeCreate, EmployeeEdit, EmployeeList} from "./resources/employee";
import {OrderCreate, OrderEdit, OrderList} from "./resources/order";
import {LoginPage} from './auth'

import dataProvider from './data/dataProvider';
import authProvider from "./auth/authProvider";
import {i18nProvider} from "./i18n/i18nProvider";

import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from "./resources/user/constants";


const App = () => {
    return (
        <Admin
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={LoginPage}
            layout={Layout}
        >
            {permissions => [
                // Only include the categories resource for admin users
                permissions === ROLE_ADMIN || permissions === ROLE_SUPER_ADMIN
                    ? [
                        <Resource
                            name="contracts"
                            list={ContractList}
                            create={ContractCreate}
                            edit={ContractEdit}
                            icon={ContractIcon}
                        />,
                        <Resource
                            name="duties"
                            list={DutyList}
                            icon={DutyIcon}
                        />,
                    ] : null,
                <Resource
                    name="orders"
                    list={OrderList}
                    edit={OrderEdit}
                    create={OrderCreate}
                    icon={OrderIcon}
                />,
                // Only include the categories resource for admin users
                permissions === ROLE_ADMIN || permissions === ROLE_SUPER_ADMIN
                    ? [
                        <Resource
                            name="partners"
                            list={PartnerList}
                            create={PartnerCreate}
                            edit={PartnerEdit}
                            icon={PartnerIcon}
                        />,
                        <Resource name="partners/addresses"/>,
                        <Resource
                            name="activities"
                            list={ActivityList}
                            create={ActivityCreate}
                            edit={ActivityEdit}
                            icon={ActivityIcon}
                        />,
                        <Resource
                            name="employees"
                            list={EmployeeList}
                            create={EmployeeCreate}
                            edit={EmployeeEdit}
                            icon={EmployeeIcon}
                        />,
                        <Resource
                            name="users"
                            list={UserList}
                            create={UserCreate}
                            edit={UserEdit}
                            icon={UserIcon}
                        />,
                    ] : null,
            ]}

        </Admin>
    );
}


export default App;

