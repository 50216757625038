import {FunctionField, useTranslate} from "react-admin";
import React from "react";
import lodash from "lodash";

export const FullNameField = ({source, record, emptyText}) => {
    const translate = useTranslate();
    const name = source ? lodash.get(record, source) : record;

    return <FunctionField
        source={source}
        record={name}
        render={r => fullNameText(emptyText ? translate(emptyText) : '')(r)}
    />
};

export const fullNameText = emptyText => r => (
    r && (r.firstName || r.lastName) ?
        `${r.firstName || ''} ${r.lastName || ''}`
        : emptyText
);