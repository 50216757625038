import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useTranslate,
    useLocale,
    SelectField,
    Pagination,
    SelectInput,
    DateInput,
    TextInput,
    useListController,
    AutocompleteInput,
    ReferenceInput,
    BooleanInput,
    FunctionField,
    useRedirect,
    ArrayField, ReferenceArrayInput, AutocompleteArrayInput,
} from 'react-admin';
import {
    ListBulkActions,
    ListActions,
    AddressField,
    ActivitiesField,
    useExpandOnlyOne,
    FullNameField,
    fullNameText,
    SingleFieldList,
    LazyFileField,
    CloneButton, safeOptionText,
} from "../../components";
import {OrdersExporter} from "../../data/export";
import {ORDER_BONUSES, ORDER_STATUS} from "./constants";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OrderCalendar from "./calendar";
import {DUTY_PERIODICITY} from "../contract/constants";
import {ROLE_USER} from "../user/constants";
import {activityString, prettyTimeString, prettyTimeSumString} from "../activity/utils";
import qs from "qs";
import {stringify} from 'query-string';
import {BoxedShowLayout, RaBox} from "ra-compact-ui";
import {makeStyles} from "@material-ui/core";
import {ACTIVITY_TYPE} from "../activity/constants";


const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1px',
        },
    },
);

const AllFields = props => {
    const classes = useStyles();
    const translate = useTranslate();

    const activities = <ActivitiesField source="duty.activities" {...props}/>
    const oneTimeDuty = <BoxedShowLayout {...props} className={classes.root}>
        <RaBox>
            <ArrayField source='oneTimeDuty.attachments' label='resources.orders.fields.oneTimeDuty.attachments'>
                <SingleFieldList emptyText={translate('resources.orders.hasNotAttachments')}>
                    <LazyFileField/>
                </SingleFieldList>
            </ArrayField>
        </RaBox>
    </BoxedShowLayout>

    return props?.record?.oneTimeDuty ? oneTimeDuty : activities;
};

const orderFilters = disabled => [
    <DateInput
        alwaysOn
        source="realizationDateFrom"
        disabled={disabled}
        label="resources.orders.filters.realizationDateFrom"
    />,
    <DateInput
        alwaysOn
        source="realizationDateUntil"
        disabled={disabled}
        label="resources.orders.filters.realizationDateUntil"
    />,
    <SelectInput
        alwaysOn
        source="status"
        choices={ORDER_STATUS}
        disabled={disabled}
    />,
    // <TextInput
    //     source="assigneeName"
    //     label="resources.orders.filters.assigneeName"
    //     disabled={disabled}
    // />,
    <ReferenceInput
        alwaysOn
        source="assignee"
        reference='employees'
        sort={{field: 'lastName', order: 'ASC'}}
        label='resources.orders.fields.assignee'
        filterToQuery={searchText => ({fullName: searchText})}
        parse={value => value && ({id: value})}
        format={value => value ? value.id : value}
        allowEmpty
        disabled={disabled}
    >
        <AutocompleteInput
            optionText={fullNameText('')}
        />
    </ReferenceInput>,
    <TextInput
        alwaysOn
        source="dutyAddress"
        label="resources.orders.filters.dutyAddress"
        disabled={disabled}
    />,
    <BooleanInput
        source="oneTime"
        defaultValue
        label="resources.orders.filters.oneTime"
        disabled={disabled}
    />,
    <BooleanInput
        source="unassigned"
        defaultValue
        label="resources.orders.filters.unassigned"
        disabled={disabled}
    />,
    <SelectInput
        source="duty.periodicity"
        choices={DUTY_PERIODICITY}
        label="resources.orders.filters.periodicity"
        disabled={disabled}
    />,
    <TextInput
        source="duty.id"
        label="resources.orders.filters.dutyId"
        disabled={disabled}
    />,
    <TextInput
        source="contractNumber"
        label="resources.orders.filters.contractNumber"
        disabled={disabled}
    />,
    <TextInput source="code" disabled={disabled}/>,
    <SelectInput
        source="bonus"
        choices={ORDER_BONUSES}
        label="resources.orders.filters.bonus"
        disabled={disabled}
    />,

    <ReferenceArrayInput
        fullWidth
        source="activityIds"
        reference="activities"
        label="resources.orders.filters.activityIds"
        sort={{field: 'shortName', order: 'ASC'}}
        filterToQuery={searchText => ({shortName: searchText})}
        perPage={100}
        allowEmpty={false}
    >
        <AutocompleteArrayInput optionText={safeOptionText(activityString)}/>
    </ReferenceArrayInput>,
    <SelectInput
        source="activityType"
        label="resources.orders.filters.activityType"
        choices={ACTIVITY_TYPE}
    />,
];

const OrderList = ({permissions, ...props}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const {selectedIds} = useListController(props);
    useExpandOnlyOne(props.resource, props.location);
    const isSomethingSelected = selectedIds && selectedIds.length > 0;

    return <List
        {...props}
        actions={<ListActions filter/>}
        filters={orderFilters(isSomethingSelected)}
        bulkActionButtons={<ListBulkActions permissions={permissions}/>}
        exporter={OrdersExporter(translate)}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]}/>}
        perPage={50}
    >
        <Datagrid
            rowClick="expand"
            expand={<AllFields/>}
        >
            {/*<TextField source="id"/>*/}
            {/*<TextField source="orderNumber"/>*/}
            <TextField source="code"/>
            <SelectField source="status" choices={ORDER_STATUS}/>
            <FunctionField
                source="realizationDate"
                render={r => {
                    if (!r) return '';
                    const options = {year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'long'};
                    if (r.oneTimeDuty) {
                        options['hour'] = 'numeric';
                        options['minute'] = 'numeric';
                    }
                    return new Date(r.realizationDate).toLocaleDateString(locale, options);
                }}
            />
            <AddressField
                source="duty.address.address"
                alternativeSource="oneTimeDuty.address"
                sortBy='duty.address.address'
            />
            <FullNameField source='assignee' emptyText='resources.orders.fields.noAssignee'/>
            <FunctionField
                source="minutes"
                render={r => r?.overrideMinutes ? prettyTimeString(r?.overrideMinutes) : prettyTimeSumString(r?.duty?.activities)}
                label='resources.orders.fields.time'
            />
            <CloneButton showFn={order => order.oneTimeDuty} omitFields={["code", "orderNumber", "oneTimeDuty.attachments",    "oneTimeDuty.id", "oneTimeDuty.address.id"]}/>
            <EditButton basePath="/orders"/>
        </Datagrid>
    </List>
};

const OrderWrapper = props => {
    const params = qs.parse(props?.location?.search, {ignoreQueryPrefix: true});
    const defaultTab = (!props.permissions || props.permissions === ROLE_USER) ? 'calendar' : 'list';
    const tab = params.tab || defaultTab;

    const redirect = useRedirect();
    const translate = useTranslate();

    const setTab = tab => {
        redirect(`/orders?${stringify({...params, tab: tab})}`)
    }

    return <div>
        <Tabs
            value={tab}
            onChange={(event, tab) => setTab(tab)}
            style={{marginBottom: '10px'}}
        >
            {props.permissions !== ROLE_USER && <Tab value="list" label={translate('resources.orders.list')}/>}
            <Tab value="calendar" label={translate('resources.orders.calendar')}/>
        </Tabs>
        {tab === 'list' && props.permissions !== ROLE_USER && <OrderList {...props}/>}
        {tab === 'calendar' && <OrderCalendar {...props}/>}
    </div>
};

export default OrderWrapper;