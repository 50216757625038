import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ArrayField,
    SelectInput,
    DateInput,
    TextInput,
    BooleanInput,
    useTranslate,
    useListController,
    FunctionField,
    Pagination,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core';
import {BoxedShowLayout, RaBox} from "ra-compact-ui";
import moment from 'moment'
import {
    ListBulkActions,
    ListActions,
    useExpandOnlyOne,
    LazyFileField,
    SingleFieldList,
    DutiesField,
    safeOptionText,
} from "../../components";
import {ContractsExporter} from "../../data/export";
import {DUTY_PERIODICITY} from "./constants";
import {activityString} from "../activity/utils";


const useStyles = makeStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1px',
        },
    },
);

const AllFields = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    return <BoxedShowLayout {...props} className={classes.root}>
        <RaBox>
            <ArrayField source='attachments' label='resources.contracts.fields.attachments'>
                <SingleFieldList emptyText={translate('resources.contracts.hasNotAttachments')}>
                    <LazyFileField/>
                </SingleFieldList>
            </ArrayField>
        </RaBox>
        <RaBox>
            <DutiesField/>
        </RaBox>
    </BoxedShowLayout>
};

const validityOptions = [
    {id: "null", name: "resources.contracts.validity.all"},
    {id: true, name: "resources.contracts.validity.valid"},
    {id: false, name: "resources.contracts.validity.invalid"}
]

const contractFilters = disabled => [
    <SelectInput
        alwaysOn
        allowEmpty={false}
        label="resources.contracts.validity.name"
        source="valid"
        choices={validityOptions}
        disabled={disabled}
    />,
    <TextInput alwaysOn source="contractNumber" disabled={disabled}/>,
    <TextInput alwaysOn source="subject" disabled={disabled}/>,
    <TextInput alwaysOn source="customer.shortName" disabled={disabled}/>,
    <DateInput
        label="resources.contracts.validity.validUntil"
        source="validUntil"
        defaultValue={moment.now()}
        disabled={disabled}
    />,
    <TextInput source="contractor.shortName" disabled={disabled}/>,
    <TextInput source="dutyAddress" label="resources.contracts.filters.dutyAddress" disabled={disabled}/>,
    <SelectInput source="periodicity" choices={DUTY_PERIODICITY} label="resources.contracts.filters.periodicity" disabled={disabled}/>,
    <BooleanInput source="winterMaintenance" defaultValue label="resources.contracts.filters.winterMaintenance" disabled={disabled} />,
    <BooleanInput source="hasAttachments" defaultValue label="resources.contracts.filters.hasAttachments" disabled={disabled}/>,
    // <TextInput source="activity" label="resources.contracts.filters.activity" disabled={disabled}/>,
    <ReferenceArrayInput
        fullWidth
        source="activities"
        reference="activities"
        sort={{field: 'shortName', order: 'ASC'}}
        label='resources.contracts.filters.activity'
        filterToQuery={searchText => ({shortName: searchText})}
        perPage={100}
        allowEmpty={false}
    >
        <AutocompleteArrayInput optionText={safeOptionText(activityString)}/>
    </ReferenceArrayInput>,
];


const ContractList = ({permissions, ...props}) => {
    const translate = useTranslate();
    const {selectedIds} = useListController(props);
    useExpandOnlyOne(props.resource, props.location);
    const isSomethingSelected = selectedIds && selectedIds.length > 0;

    return <List
        {...props}
        actions={<ListActions filter/>}
        filters={contractFilters(isSomethingSelected)}
        filterDefaultValues={{valid: "null"}}
        bulkActionButtons={<ListBulkActions permissions={permissions} remove/>}
        exporter={ContractsExporter(translate)}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]}/>}
        perPage={50}
        sort={{ field: 'subject', order: 'ASC' }}
    >
        <Datagrid
            rowClick="expand"
            expand={<AllFields {...props}/>}
            optimized
        >
            {/*<TextField source="id"/>*/}
            <TextField source="contractNumber"/>
            <TextField source="subcontractNumber"/>
            <TextField source="customer.shortName"/>
            <TextField source="contractor.shortName"/>
            <TextField source="subject"/>
            <DateField source="validFrom"/>
            <DateField source="validUntil" emptyText={translate('resources.contracts.validity.infinity')}/>
            <FunctionField
                source="attachments"
                render={r => r && r.attachments ? `${r.attachments.length}` : '0'}
                label='resources.contracts.fields.attachmentsCount'
                sortable={false}
            />
            <EditButton basePath="/contracts"/>
        </Datagrid>
    </List>
};

export default ContractList;