import React from "react";
import {
    Labeled,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin";
import lodash from 'lodash'


export const LabeledAddressInput = props => (
    <Labeled label='app.address.title' {...props}>
        <AddressInput {...props}/>
    </Labeled>
);

export const AddressInput = ({flex, justifyContent, defaultValues, ...props}) => (
    <div>
        <TextInput {...props} source={`${props.source}.zip`} label='app.address.zip'
                   initialValue={defaultValues && defaultValues.zip}/>
        <TextInput {...props} source={`${props.source}.city`} label='app.address.city'
                   initialValue={defaultValues && defaultValues.city}/>
        <TextInput {...props} source={`${props.source}.state`} label='app.address.state'
                   initialValue={defaultValues && defaultValues.state}/>
        <TextInput {...props} source={`${props.source}.street`} label='app.address.street'/>
        <TextInput {...props} source={`${props.source}.number`} label='app.address.number'/>
    </div>
);

export const AddressFields = [
    <TextField source="address.zip" label='app.address.zip'/>,
    <TextField source="address.city" label='app.address.city'/>,
    <TextField source="address.state" label='app.address.state'/>,
    <TextField source="address.street" label='app.address.street'/>,
    <TextField source="address.number" label='app.address.number'/>,
];

export const AddressField = props => {
    const record = useRecordContext(props);
    let address = props.source ? lodash.get(record, props.source) : record;
    if (!address && props.alternativeSource) {
        address = lodash.get(record, props.alternativeSource);
    }
    const addressTitle = getAddressTitle(address);
    return <span>{addressTitle}</span>;
};

export const getAddressTitle = address => {
    if (!address) return '';
    const {city, street, number} = address;
    const c = city ? city : '';
    const s = street ? street : '';
    const n = number ? number : '';
    return `${c}${c && (s || n) && ', '}${s}${s && n && ' '}${n}`
};