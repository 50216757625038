import React from 'react';
import {
    Edit,
    SimpleForm,
    BooleanInput,
    BooleanField,
    TextField,
    SelectInput,
    SelectField,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
} from 'react-admin';
import {ROLE_SUPER_ADMIN, availableRoles, ROLES} from './constants';
import {fullNameText, Toolbar} from "../../components";


export const UserEdit = ({permissions, ...props}) => {
    return <Edit
        mutationMode="pessimistic"
        {...props} >
        <SimpleForm
            toolbar={<Toolbar permissions={permissions}/>}
        >
            <TextField source="id"/>
            <TextField source="email" type="email"/>
            <TextInput source="firstName"/>
            <TextInput source="lastName"/>
            {permissions === ROLE_SUPER_ADMIN ? <SelectInput source="role" choices={availableRoles(permissions)}/> :
                <SelectField source="role" choices={ROLES}/>}
            {permissions === ROLE_SUPER_ADMIN ? <BooleanInput source="active"/> : <BooleanField source="active"/>}

            <ReferenceInput
                source="employee.id"
                reference="employees"
                sort={{field: 'firstName,lastName', order: 'ASC'}}
                label='resources.users.fields.employee'
                allowEmpty
            >
                <AutocompleteInput optionText={fullNameText('')}/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};

export default UserEdit;