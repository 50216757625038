import {groupBy, path} from 'ramda';


export const getEvents = (dataProvider, calendarFilter) => (
    (fetchInfo, successCallback, failureCallback) => {
        dataProvider.getList('orders', {
            filter: {
                ...calendarFilter,
                realizationDateFrom: fetchInfo.start.valueOf(),
                realizationDateUntil: fetchInfo.end.valueOf(),
            },
            pagination: {page: 1, perPage: 10000},
            sort: {field: 'realizationDate,assignee,duty.address.address', order: 'ASC'},
        }).then(({data}) => {
            successCallback(transformOrders(data))
        }).catch(failureCallback);
    });

const transformOrders = (orders) => {
    const ordersByRealizationDate = groupBy(path(['realizationDate']))(orders);
    return Object.keys(ordersByRealizationDate).flatMap(realizationDate => {

        const o = ordersByRealizationDate[realizationDate];
        const ordersByAssigneeId = groupBy(path(['assignee', 'id']))(o);

        return Object.keys(ordersByAssigneeId).map(assigneeId => {
            return {
                allDay: true,
                start: realizationDate,
                end: realizationDate,
                extendedProps: {
                    realizationDate: realizationDate,
                    assigneeId: assigneeId,
                    orders: ordersByAssigneeId[assigneeId],
                },
            }
        }).reverse();
    });
};

export const getEventDrop = (dataProvider, notify, successCallback) => (
    (orderId, newDate, assigneeId) => {
        if (!orderId || !newDate) return;

        dataProvider
            .updateRealizationDateAndAssigneeEmployee({
                orderIds: [orderId],
                realizationDate: newDate,
                employeeId: assigneeId
            })
            .then(() => {
                notify('app.message.updated_order_realization_date_and_assign', 'info');
                successCallback();
            })
            .catch(() => {
                notify('app.message.updated_order_realization_date_and_assign_fail', 'warning')
            })
    });
