import decodeJwt from 'jwt-decode';
import {apiUrl} from "../Config";


const authProvider = {
    acceptInvite: ({invitationToken, email, password}) => {
        const request = createRequest('POST', 'auth/acceptInvite', {
            activationToken: invitationToken,
            email: email,
            password: password
        });
        return loginResponse(request);

    },
    forgottenPassword: ({email}) => {
        return createRequest('POST', 'auth/forgotten-password', {email: email});
    },
    resetPassword: ({passwordResetToken, email, password}) => {
        const request = createRequest('POST', 'auth/reset-password', {
            email: email,
            passwordResetToken: passwordResetToken,
            password: password
        });
        return loginResponse(request);
    },
    login: ({username, password}) => {
        const request = createRequest('POST', 'auth/login', {email: username, password: password});
        return loginResponse(request);
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        try {
            const { id, firstName, lastName } = JSON.parse(localStorage.getItem('user'));
            return Promise.resolve({ id, fullName: `${firstName || ''} ${lastName || ''}` });
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

const createRequest = (method, url, payload) => {
    return fetch(new Request(`${apiUrl()}/${url}`, {
        method: method,
        body: JSON.stringify(payload),
        headers: new Headers({'Content-Type': 'application/json'}),
    })).then(response => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
}

const loginResponse = (request) => request
    .then(({token, user}) => {
        const decodedToken = decodeJwt(token);
        localStorage.setItem('token', token);
        localStorage.setItem('permissions', decodedToken.permissions);
        const {id, email, firstName, lastName} = user;
        localStorage.setItem('user', JSON.stringify({id, email, firstName, lastName}));
    });

export default authProvider;