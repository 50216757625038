import {fetchUtils} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import {stringify} from 'query-string';

import {apiUrl} from "../Config";
import {recursivelyBase64ToFiles, recursivelyFilesToBase64} from './fileUtils';

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    // add your own headers here
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    return fetchUtils.fetchJson(url, options);
};

const defaultProvider = simpleRestProvider(apiUrl(), fetchJson);
const provider = Object.keys(defaultProvider)
    .map(k => ({
            [k]: (resource, params) => recursivelyFilesToBase64(params)
                .then(convertedParams => defaultProvider[k](resource, convertedParams))
                .then(response => recursivelyBase64ToFiles(response))
        })
    )
    .reduce((p, c) => ({...p, ...c}), {})

const dataProvider = {
    ...provider,
    getList: (resource, params) => (
        provider.getList(resource, params)
            .then(page => ({
                data: page.data.content,
                total: page.data.totalElements,
            })).then(r => {
            // console.log(r);
            return r;
        })
    ),
    getMany: (resource, params) => (
        provider.getMany(resource, params)
            .then(page => ({
                data: page.data.content,
            }))
    ),
    getManyReference: (resource, params) => (
        provider.getList(resource, params)
    ),
    deleteMany: (resource, params) => {
        const query = {filter: JSON.stringify({id: params.ids})}
        const url = `${apiUrl()}/${resource}?${stringify(query)}`;
        return fetchJson(url, {method: 'DELETE'}).then(({json}) => ({data: json}));
    },
    generateOrders: ({from, until, contractIds, dutyIds}) => {
        const url = `${apiUrl()}/orders/generate`;
        const body = JSON.stringify({from: from, until: until, contractIds: contractIds, dutyIds: dutyIds});
        return fetchJson(url, {method: 'POST', body: body}).then(({json}) => ({data: json}));
    },
    assignEmployeeToOrders: ({orderIds, employeeId}) => {
        const url = `${apiUrl()}/orders/assign`;
        const body = JSON.stringify({orderIds: orderIds, employeeId: employeeId});
        return fetchJson(url, {method: 'PUT', body: body}).then(({json}) => ({data: json}));
    },
    updateOrdersStatus: ({orderIds, status}) => {
        const url = `${apiUrl()}/orders/updateStatus`;
        const body = JSON.stringify({orderIds: orderIds, status: status});
        return fetchJson(url, {method: 'PUT', body: body}).then(({json}) => ({data: json}));
    },
    updateRealizationDate: ({orderIds, realizationDate}) => {
        const url = `${apiUrl()}/orders/realizationDate`;
        const body = JSON.stringify({orderIds: orderIds, realizationDate: realizationDate});
        return fetchJson(url, {method: 'PUT', body: body}).then(({json}) => ({data: json}));
    },
    updateRealizationDateAndAssigneeEmployee: ({orderIds, realizationDate, employeeId}) => {
        const url = `${apiUrl()}/orders/realizationDateAndAssign`;
        const body = JSON.stringify({orderIds: orderIds, realizationDate: realizationDate, employeeId: employeeId});
        return fetchJson(url, {method: 'PUT', body: body}).then(({json}) => ({data: json}));
    },
    updateOrdersBonus: ({orderIds, bonus}) => {
        const url = `${apiUrl()}/orders/bonus`;
        const body = JSON.stringify({orderIds: orderIds, bonus: bonus});
        return fetchJson(url, {method: 'PUT', body: body}).then(({json}) => ({data: json}));
    },
}

export default dataProvider