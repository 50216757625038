import React from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    Labeled,
    SelectInput,
    DateTimeInput,
    DateInput,
    AutocompleteInput,
    ReferenceInput,
    FormDataConsumer,
    useTranslate,
    NumberInput,
} from 'react-admin';
import {DutyField, fullNameText, OneTimeDutyInput} from "../../components";
import {NO_ORDER_BONUS, ORDER_BONUSES, ORDER_STATUS} from "./constants";
import {positiveNumber} from "../../data/validation";
import {ACTIVITY_TYPE} from "../activity/constants";
import {dateToUtcString} from "../../components/utils";

export const OrderEdit = ({permissions, ...props}) => {
    const translate = useTranslate();

    return <Edit mutationMode='pessimistic' {...props}>
        <SimpleForm redirect={(basePath) => `${basePath}${props?.location?.search}`}>
            <TextField source="code"/>
            <SelectInput source="status" choices={ORDER_STATUS}/>
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    return formData.oneTimeDuty
                        ? <DateTimeInput
                            {...rest}
                            source="realizationDate"
                            label="resources.orders.fields.oneTimeDuty.realizationDate"
                            parse={value => value}
                        />
                        : <DateInput
                            {...rest}
                            source="realizationDate"
                            label="resources.orders.fields.realizationDate"
                            parse={dateToUtcString}
                        />
                }}
            </FormDataConsumer>
            <SelectInput source="bonus" choices={ORDER_BONUSES} defaultValue={NO_ORDER_BONUS}/>
            <NumberInput
                source="overrideMinutes"
                min={0}
                validate={positiveNumber}
            />
            <SelectInput
                source="overrideActivityType"
                choices={ACTIVITY_TYPE}
                resettable
            />
            <ReferenceInput
                source="assignee"
                reference="employees"
                sort={{field: 'lastName', order: 'ASC'}}
                filter={{active: true}}
                filterToQuery={searchText => ({fullName: searchText})}
                label='resources.orders.fields.assignee'
                parse={value => value && ({id: value})}
                format={value => value ? value.id : value}
                allowEmpty allowNull={null}
            >
                <AutocompleteInput optionText={fullNameText(translate('resources.orders.fields.noAssignee'))}/>
            </ReferenceInput>

            <FormDataConsumer>
                {({formData}) => {
                    return formData.oneTimeDuty
                        ? <OneTimeDutyInput/>
                        : <Labeled fullWidth label="resources.orders.fields.duty.name">
                            <DutyField source="duty" record={formData}/>
                        </Labeled>
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};

export default OrderEdit;